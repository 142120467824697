import * as React from "react";
import { Footer } from "@nju/feature-portal-footer";
import { Header } from "@nju/feature-portal-header";
import { FallbackProps } from "react-error-boundary";
import { LayoutWrapper, GeneralErrorNotification, BlockContent } from "@nju/ui";
import { portalRoutes } from "@nju/routes";

export function GlobalErrorBoundary(props: Partial<FallbackProps>) {
  React.useEffect(() => {
    console.error("Unexpected error occurred", props.error);
  }, [props.error]);

  return (
    <LayoutWrapper>
      <Header />
      <BlockContent
        grid="narrow"
        spacing={{
          "@onlyMobile": "l",
          "@fromTablet": "xl",
        }}
        tone="secondary"
      >
        <GeneralErrorNotification
          callback={() => {
            window.location.href = portalRoutes.index.get();
          }}
          copy="odśwież"
        />
      </BlockContent>
      <Footer />
    </LayoutWrapper>
  );
}
