import { useButton } from "@react-aria/button";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import mergeRefs from "merge-refs";
import * as React from "react";
import { Loader } from "../Loader";
import { styles } from "./styles";
import { Pressable } from "./types";

export interface ButtonProps extends Pressable {
  type?: JSX.IntrinsicElements["button"]["type"];
  isDisabled?: boolean;
  onPress?(): void;
  autoFocus?: boolean;
  isLoading?: boolean;
  testId?: string;
}

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps>
>(function Button(
  {
    align,
    children,
    size = "m",
    variant = "primary",
    withUnderline = false,
    display,
    isLoading = false,
    testId,
    ...props
  },
  ref
) {
  const innerRef = React.useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, innerRef);
  const { hoverProps, isHovered } = useHover(props);

  return (
    <button
      className={styles.element({
        align,
        size,
        variant,
        withUnderline,
        display,
        isLoading,
        hover: isHovered,
      })}
      data-cy={testId}
      ref={mergeRefs(ref, innerRef)}
      {...mergeProps(hoverProps, buttonProps)}
    >
      {isLoading && <Loader className={styles.spinner()} tone="dark" />}
      <span className={styles.hidden({ isLoading })}>{children}</span>
    </button>
  );
});
