import * as React from "react";
import { css } from "@nju/css";
import { Icon } from "@nju/icons";
import { getFeatureFlagValue } from "@nju/feature-flags";
import { copyToClipboard } from "@nju/utils";
import { useDebugContext } from "./DebugContext";

type Timer = ReturnType<typeof setTimeout>;

export function Debug() {
  const [show, setShow] = React.useState(false);
  const [message, setMessage] = React.useState<undefined | string>(undefined);
  const [timer, setTimer] = React.useState<Timer | undefined>(undefined);
  const [isVisible, setIsVisible] = React.useState(false);
  const list = useDebugContext();
  const keys = Object.keys(list);

  const handleCopyToClipboard = async (id: string, data: unknown) => {
    setMessage(
      await copyToClipboard(JSON.stringify({ [id]: data }, undefined, 2))
    );

    if (timer) {
      clearTimeout(timer);
      setTimer(undefined);
    }

    setTimer(
      setTimeout(() => {
        setMessage("");
      }, 3000)
    );
  };

  React.useEffect(() => {
    setShow(getFeatureFlagValue("debugComponent") === "1");
  }, []);

  if (!show) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  return (
    <div className={styles.wrapper()}>
      {isVisible && (
        <div className={styles.content()}>
          {keys.length === 0 && (
            <p className={styles.emptyList()}>Lista jest pusta</p>
          )}
          {Object.entries(list).map(([id, data]) => {
            return (
              <button
                className={styles.button()}
                key={id}
                onClick={() => {
                  handleCopyToClipboard(id, data);
                }}
              >
                {id}
              </button>
            );
          })}
        </div>
      )}
      <button
        className={styles.debugButton()}
        onClick={() => {
          return setIsVisible(!isVisible);
        }}
      >
        <Icon height={30} name="cog" tone="secondary" width={30} />
        {message && (
          <div className={styles.message()}>
            <p>{message}</p>
          </div>
        )}
      </button>
    </div>
  );
}

const styles = {
  emptyList: css({
    border: "1px solid $geyser",
    padding: "$10",
    borderRadius: "$8",
    backgroundColor: "$white",
  }),
  message: css({
    position: "absolute",
    left: "50px",
    top: "7px",
    borderRadius: "10px",
    padding: "3px 10px",
    color: "$white",
    overflow: "hidden",
    whiteSpace: "nowrap",
    backgroundColor: "$black",
  }),
  wrapper: css({
    position: "fixed",
    bottom: "24px",
    left: "24px",
  }),
  debugButton: css({
    border: "none",
    width: "50px",
    position: "relative",
    height: "50px",
    backgroundColor: "$cerulean",
    p: "$10",
    boxShadow: "$cerulean",
    borderRadius: "50%",
    cursor: "pointer",
  }),
  content: css({
    position: "relative",
    top: "-10px",
    display: "flex",
    flexDirection: "column",
  }),
  button: css({
    border: "1px solid $geyser",
    padding: "$10",
    borderRadius: "$8",
    backgroundColor: "$white",
    "&:hover": {
      borderColor: "$cerulean",
    },
    "&:active": {
      backgroundColor: "$cerulean",
      color: "$white",
    },
  }),
};
