import { cx } from "@nju/css";

type ILayoutProps = {
  background?: "white" | "gray";
};

export function LayoutWrapper({
  children,
  background = "white",
}: React.PropsWithChildren<ILayoutProps>) {
  return (
    <div
      className={cx(
        "grid grid-rows-auto-1-auto min-h-screen",
        background === "white" ? "bg-white" : "bg:porcelain"
      )}
    >
      {children}
    </div>
  );
}
