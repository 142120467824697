import * as React from "react";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { BlockContent, Text } from "@nju/ui";
import { Icon } from "@nju/icons";
import { Documents } from "./Documents";
import Link from "next/link";

interface IFooterProps {
  children?: React.ReactNode;
}

export function Footer({ children }: IFooterProps) {
  const scrollUp = React.useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const year = React.useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <footer>
      <BlockContent tone="primary">
        <div className="pt-12 pb-6" data-cy="footer">
          {children}
          {children && (
            <div className="bg-geyser h-microgap w-full my-3 from-tablet:mt-6 from-tablet:bp-4 from-desktop:my-8" />
          )}
          <div className="flex flex-col from-tablet:flex-row from-tablet:justify-between gap-y-8">
            <div className="flex flex-col">
              <Documents />
            </div>
            <div className="flex flex-col items-center gap-y-8">
              <div className="flex items-center gap-4 max-w-fit">
                <Link
                  aria-label="Facebook"
                  className="w-12 h-12"
                  data-cy="footer-facebook-button"
                  href="https://www.facebook.com/njumobile"
                  rel="noreferrer"
                >
                  <Icon className="w-full h-full" name="facebook-incircle" />
                </Link>
                <Link
                  aria-label="Instagram"
                  className="w-12 h-12"
                  data-cy="footer-instagram-button"
                  href="https://www.instagram.com/njumobile"
                  rel="noreferrer"
                >
                  <Icon className="w-full h-full" name="instagram-incircle" />
                </Link>
                <Link
                  aria-label="Youtube"
                  className="w-12 h-12"
                  data-cy="footer-youtube-button"
                  href="https://www.youtube.com/njumobile"
                  rel="noreferrer"
                >
                  <Icon className="w-full h-full" name="youtube-incircle" />
                </Link>
                <Icon
                  className="w-12 h-12"
                  data-cy="footer-award-icon"
                  name="award"
                />
              </div>
              <div className="flex items-center text-size-24 from-tablet:ml-auto max-w-fit gap-3">
                <Text size="s">&copy; {year} nju.</Text>
                <Link
                  className="w-12 h-12"
                  data-cy="footer-go-up-button"
                  href="#"
                  onClick={scrollUp}
                >
                  <Icon className="w-full h-full" name="arrow-inside" />
                  <VisuallyHidden>Wróć do góry strony</VisuallyHidden>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <img alt="pipik" src="/images/pipik_footer.svg" />
        </div>
      </BlockContent>
    </footer>
  );
}
