import { BasicError } from "./Error";
import { Err, Ok, Result } from "./types";

export const ok = <Value>(value: Value): Ok<Value> => {
  return {
    value,
    isOk: true,
    isError: false,
    __ok: true,
    unwrap: () => {
      return value;
    },
  };
};

export const err = <Error>(err: Error): Err<Error> => {
  return {
    err,
    isOk: false,
    isError: true,
    __ok: false,
    unwrap: () => {
      throw err;
    },
  };
};

/**
 * @deprecated use isOk on Result instead
 */
export const isOk = <Value, Error extends BasicError>(
  result: Result<Error, Value>
): result is Ok<Value> => {
  return result.__ok === true;
};

/**
 * @deprecated use isOk on Result instead
 */
export const isError = <Value, Error extends BasicError>(
  result: Result<Error, Value>
): result is Err<Error> => {
  return !!result && "__ok" in result && result.__ok === false;
};
