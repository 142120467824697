import * as React from "react";
import { marked } from "marked";
import { Icon, IconName } from "@nju/icons";
import { Separator } from "../Separator";

interface IInfoPanelProps {
  icon: IconName;
  title: string;
  description?: string | JSX.Element;
  children?: React.ReactNode;
  testId?: string;
}

export function InfoPanel({
  icon,
  title,
  description,
  children,
  testId,
}: IInfoPanelProps) {
  return (
    <div
      className="
        flex items-center justify-between p-4 border-solid border-1 border-geyser rounded-panel
        only-mobile:flex-col from-desktop:w-full
      "
      data-cy={testId}
    >
      <div className="flex">
        <Icon
          className="shrink-0 mr-4"
          height={26}
          name={icon}
          tone="accent"
          width={26}
        />
        <div className="flex gap-1 flex-col">
          <span
            className="font-bold text-size-16 from-tablet:text-size-24"
            dangerouslySetInnerHTML={{ __html: marked(title) }}
          />
          {typeof description === "string" ? (
            <span
              className="text-size-14 from-tablet:text-size-16"
              dangerouslySetInnerHTML={{ __html: marked(description) }}
            />
          ) : (
            <>{description}</>
          )}

          {children && (
            <Separator
              size={{
                "@onlyMobile": "s",
                "@fromTablet": "none",
              }}
              tone="invisible"
            />
          )}
        </div>
      </div>
      {children}
    </div>
  );
}
