import { cx } from "@nju/css";
import { Icon } from "@nju/icons";
import { portalRoutes } from "@nju/routes";
import { Button } from "@nju/ui";
import { type PropsWithChildren } from "react";
import { Logo } from "../shared";

interface IHeaderMenuMobilePopupProps {
  onClose: () => void;
  open: boolean;
}

export function HeaderMenuMobilePopup({
  onClose,
  children,
  open,
}: PropsWithChildren<IHeaderMenuMobilePopupProps>) {
  return (
    <div className="from-tablet:hidden">
      <div
        className={cx(
          "absolute top-0 bottom-0 left-0 w-[330px] bg-white z-6 max-w-full transition-transform",
          {
            "translate-x-0": open,
            "translate-x-[-100%]": !open,
          }
        )}
      >
        <div className="relative w-full h-full overflow-hidden pb-[72px]">
          <div className="absolute top-[26px] right-0 bg-daintree rounded-tl-[8px] rounded-bl-[8px] overflow-hidden">
            <Button onPress={onClose} size="inline" variant="simple">
              <Icon
                className="m-[8px] "
                height={18}
                name="arrow-from-right"
                tone="secondary"
                width={22}
              />
            </Button>
          </div>
          <div className="py-[26px] px-6">
            <Logo href={portalRoutes.index.get()} tone="neutral" />
          </div>
          <div
            className={cx(
              "flex-col overflow-y-scroll h-full box-content pl-3 pr-5 mr-[-16px]"
            )}
          >
            {children}
          </div>
        </div>
      </div>
      <div
        className={cx(
          "fixed top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.5)] z-5 transition-opacity",
          {
            "translate-x-0 opacity-1": open,
            "translate-x-[-100%] opacity-0": !open,
          }
        )}
      />
    </div>
  );
}
