import { Icon } from "@nju/icons";
import { Text } from "@nju/ui";
import { cx } from "@nju/css";
import Link from "next/link";
import * as React from "react";
import { portalRoutes } from "@nju/routes";
import { IHeaderMenuLinksProps } from "../types";
import { useRouter } from "next/router";
import { useUserLoginState } from "@nju/data-access-portal-user";
import { logout } from "@nju/data-access-portal-user";
import { HeaderMenuDesktopActiveItemLink } from "./HeaderMenuDesktopActiveItemLink";
import { IOfferMode } from "@nju/data-access-portal-offers";

interface IHeaderMenuDesktopActiveAccountProps {
  links: IHeaderMenuLinksProps[];
  mode: IOfferMode;
}

export function HeaderMenuDesktopActiveAccount({
  links,
  mode,
}: IHeaderMenuDesktopActiveAccountProps) {
  const { isLoggedIn } = useUserLoginState();
  const router = useRouter();

  const logoutUser = React.useCallback(() => {
    logout();
    router.push(portalRoutes.index.get());
  }, [router]);

  const mapHeaderLinks = links
    ?.filter((item) => {
      return item.loggedIn === isLoggedIn;
    })
    ?.map((item, index) => {
      return (
        <HeaderMenuDesktopActiveItemLink
          key={`headerItem_${index}`}
          {...item}
          isFirst={index === 0}
        />
      );
    });

  if (!links || links.length === 0) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  return (
    <div className="flex justify-between items-start only-tablet:items-end only-tablet:flex-col-reverse">
      <div
        className={cx(
          "flex flex-row only-tablet:flex-col flex-wrap from-desktop:justify-end items-end from-desktop:items-start",
          {
            "from-desktop:w-[55%]": isLoggedIn,
            "w-full": isLoggedIn,
          }
        )}
      >
        {mapHeaderLinks}
      </div>
      <div
        className={cx("from-desktop:h-[165px] flex", {
          "only-tablet:w-[440px] from-desktop:w-[45%]": isLoggedIn,
          hidden: !isLoggedIn,
        })}
      >
        <div className="flex justify-start items-start w-full">
          <div className="only-tablet:w-[230px] from-desktop:w-1/2 h-full bg-white flex flex-col gap-4 border-r-1 border-geyser pr-5 from-desktop:pl-5">
            <Link href={portalRoutes.mojeKonto.index.get({ mode })}>
              <div className="h-[26px]">
                <div className="flex gap-3 items-center justify-start hover:underline">
                  <Icon
                    data-cy="agent-menu-button"
                    height={24}
                    name="user-circle-small"
                    width={24}
                  />
                  <Text size="l">mój profil</Text>
                </div>
              </div>
            </Link>
          </div>
          <div className="only-tablet:w-[230px] from-desktop:w-1/2 bg-white flex flex-col gap-4 pl-5">
            <div className="h-[26px] cursor-pointer" onClick={logoutUser}>
              <div className="flex gap-3 items-center justify-start hover:underline">
                <Icon
                  data-cy="agent-menu-button"
                  height={24}
                  name="sign-out"
                  width={24}
                />
                <Text size="l">wyloguj się</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
