import { useUserLoginState } from "@nju/data-access-portal-user";
import { Icon } from "@nju/icons";
import Link from "next/link";
import * as React from "react";
import { HeaderMenuMobilePopup } from "./HeaderMenuMobilePopup";
import { headerMenu } from "../consts";
import { Text } from "@nju/ui";
import { cx } from "@nju/css";
import { useRouter } from "next/router";
import { portalRoutes } from "@nju/routes";
import { logout } from "@nju/data-access-portal-user";
import { HeaderMenuMobileAccountItem } from "./HeaderMenuMobileAccountItem";
import { IOfferMode } from "@nju/data-access-portal-offers";

interface IHeaderMenuMobileAccountProps {
  handleToggleMenuMobileAccountOpen: () => void;
  menuMobileAccountOpen: boolean;
  mode: IOfferMode;
}

export function HeaderMenuMobileAccount({
  handleToggleMenuMobileAccountOpen,
  menuMobileAccountOpen,
  mode,
}: IHeaderMenuMobileAccountProps) {
  const { isLoggedIn } = useUserLoginState();
  const router = useRouter();

  const logoutUser = React.useCallback(() => {
    logout();
    router.push(portalRoutes.index.get());
  }, [router]);

  const filterNavigationMenu = headerMenu.account.links?.filter((item) => {
    return item.loggedIn === isLoggedIn;
  });

  const navigationMenu = filterNavigationMenu.map((props, index) => {
    const isLast = index === filterNavigationMenu.length - 1;

    return (
      <HeaderMenuMobileAccountItem
        isLast={isLast}
        key={`navigationMenuLink_${index}`}
        {...props}
      />
    );
  });

  return (
    <HeaderMenuMobilePopup
      onClose={handleToggleMenuMobileAccountOpen}
      open={menuMobileAccountOpen}
    >
      <div
        className={cx("from-desktop:h-[165px] flex", {
          "only-tablet:w-[440px] from-desktop:w-[45%]": isLoggedIn,
          hidden: !isLoggedIn,
        })}
      >
        <div className="flex-col justify-start items-start pb-2 w-full">
          <div className="bg-white flex flex-col gap-4 border-b-1 border-geyser">
            <Link
              className="hover:underline"
              href={portalRoutes.mojeKonto.index.get({
                mode,
              })}
            >
              <div className="cursor-pointer py-5">
                <div className="flex gap-3 items-center justify-start">
                  <Icon
                    data-cy="agent-menu-button"
                    height={24}
                    name="user-circle-small"
                    width={24}
                  />
                  <Text size="l">mój profil</Text>
                </div>
              </div>
            </Link>
          </div>
          <div className="flex flex-col gap-4">
            <div
              className="cursor-pointer py-5 mb-[-30px]"
              onClick={logoutUser}
            >
              <div className="flex gap-3 items-center justify-start hover:underline">
                <Icon
                  data-cy="agent-menu-button"
                  height={24}
                  name="sign-out"
                  tone="neutral"
                  width={24}
                />
                <Text size="l">wyloguj się</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      {navigationMenu}
    </HeaderMenuMobilePopup>
  );
}
