import { z } from "zod";
import { route } from "./route";

const mode = z.enum(["mobile", "ftth"]);

export const salesRoutes = {
  index: route("/"),
  flags: route("/flags"),
  instrukcjeOferty: route("/instrukcje-oferty"),
  panel: route("/panel"),
  statusKlienta: route("/status-klienta"),
  statusKlientaSzczegoly: route("/status-klienta-szczegoly"),
  platnosc: route("/platnosc"),
  regulaminyCenniki: route("/regulaminy-cenniki"),
  regulaminyCennikiArchiwalne: route("/regulaminy-cenniki-archiwalne"),
  podsumowanie: route("/podsumowanie"),
  terminInstalacji: route("/termin-instalacji"),
  ups: route("/ups"),
  obsluga: {
    cookies: route("/obsluga/cookies"),
  },
  weryfikacjaTozsamosci: route(
    "/weryfikacja-tozsamosci",
    z.object({
      mode,
    })
  ),
  wybierzOferte: route("/wybierz-oferte"),
  zaloguj: route("/zaloguj"),
  zalozKonto: route("/zaloz-konto", z.object({ mode })),
  zamowienieZlozone: route(
    "/zamowienie-zlozone",
    z.object({
      mode,
    })
  ),
  zamowienieWTrakcie: route("/zamowienie-w-trakcie"),
  sprawdzDostepnosc: {
    index: route("/sprawdz-dostepnosc"),
    uslugaNiedostepna: route("/sprawdz-dostepnosc/usluga-niedostepna"),
  },
  dyspozycjeSprzedazowe: route("/dyspozycje-sprzedazowe"),
  klientObslugiwany: route("/klient-obslugiwany"),
  czyZachowacNumer: route(
    "/czy-zachowac-numer",
    z.object({
      mode,
      oferta: z.string().optional(),
    })
  ),
  sposobDostawy: route(
    "/sposob-dostawy",
    z.object({
      mode,
      oferta: z.string().optional(),
    })
  ),
  wpiszNumerKartySim: route(
    "/wpisz-numer-karty-sim",
    z.object({
      mode,
      oferta: z.string().optional(),
    })
  ),
  migracjaNumeru: {
    index: route(
      "/migracja-numeru",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
      })
    ),
    otp: route(
      "/migracja-numeru/otp",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
        _t: z.string(),
      })
    ),
    sprawdzMozliwoscMigracji: route(
      "/migracja-numeru/sprawdz-mozliwosc",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
      })
    ),
    migracjaNiemozliwa: route(
      "/migracja-numeru/migracja-niemozliwa",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
      })
    ),
    akceptacjaWarunkow: route(
      "/migracja-numeru/akceptacja-warunkow",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
      })
    ),
    prawieGotowe: route(
      "/migracja-numeru/prawie-gotowe",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
      })
    ),
    typOfertyMobilnej: route(
      "/migracja-numeru/typ-oferty-mobilnej",
      z.object({ mode: z.literal("mobile") })
    ),
    prepaid: {
      wybierzDateMigracji: route(
        "/migracja-numeru/prepaid/wybierz-date-migracji",
        z.object({ mode })
      ),
    },
    postpaid: {
      terminMigracji: route(
        "/migracja-numeru/postpaid/termin-migracji",
        z.object({ mode })
      ),
      wybierzDateMigracji: route(
        "/migracja-numeru/postpaid/wybierz-date-migracji",
        z.object({ mode })
      ),
    },
    przeniesienieNiemozliwe: route(
      "/migracja-numeru/przeniesienie-niemozliwe",
      z.object({
        mode: z.literal("mobile"),
        oferta: z.string(),
      })
    ),
  },
  wybierzNumer: route(
    "/wybierz-numer",
    z.object({
      mode: z.literal("mobile"),
      oferta: z.string(),
    })
  ),
  adresDostawy: route(
    "/adres-dostawy",
    z.object({
      mode: z.literal("mobile"),
      oferta: z.string().optional(),
    })
  ),
  trwajaPrace: route("/trwaja-prace"),
  pomoc: route("/pomoc"),
  wybierak: route(
    "/wybierak",
    z.object({
      amount: z.string().optional(),
      mode: z.literal("mobile"),
    })
  ),
  idziemyDalej: route(
    "/idziemy-dalej",
    z.object({
      screenVariant: z.enum(["failed", "success"]).optional(),
    })
  ),
};
