import { Text, A, Badge } from "@nju/ui";
import Link from "next/link";
import { cx } from "@nju/css";
import { Icon } from "@nju/icons";
import { IHeaderMenuItemProps } from "../types";

interface IHeaderMenuDesktopActiveItemComponentProps {
  isLast: boolean;
  isFirst: boolean;
  oddItem: boolean;
}

export function HeaderMenuDesktopActiveItem({
  icon,
  links,
  title,
  isLast,
  isFirst,
  oddItem,
}: IHeaderMenuItemProps & IHeaderMenuDesktopActiveItemComponentProps) {
  const has2Rows = links.length > 4;

  const mapLinks = links?.map((itemLink, indexLink) => {
    return (
      <div
        className="min-h-[27px] flex flex-row gap-1 pl-[39px]"
        key={`headerItemLink_${indexLink}`}
      >
        <Link className="hover:underline" href={itemLink.href} passHref={true}>
          <A size="inline" variant="neutral">
            <Text size="l" tone="neutral">
              {itemLink.title}
            </Text>
          </A>
        </Link>
        {itemLink.badge && (
          <Badge
            backgroundCerulean={itemLink.badge.tone === "accent"}
            badgeText={itemLink.badge?.title}
            small={true}
          />
        )}
      </div>
    );
  });

  return (
    <div
      className={cx("h-[214px] bg-white flex flex-col gap-4", {
        "border-r-1 border-geyser pl-5": !isFirst,
        "border-r-1 border-geyser pl-5 from-desktop:pl-0 pr-5": isFirst,
        "only-tablet:border-none": oddItem || has2Rows || isLast,
        "border-none": isLast,
        "from-desktop:pl-5": !isFirst,
        "w-1/2 from-desktop:w-1/4": !has2Rows,
        "w-full from-desktop:w-1/2": has2Rows,
      })}
    >
      <div className="h-[26px]">
        <div className="flex gap-3 items-center justify-start">
          <Icon
            data-cy="agent-menu-button"
            height={24}
            name={icon}
            tone="accent"
            width={24}
          />
          <Text size="l" tone="accent">
            {title}
          </Text>
        </div>
      </div>
      <div className="flex flex-col gap-4 flex-wrap h-full">{mapLinks}</div>
    </div>
  );
}
