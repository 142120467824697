import { cx } from "@nju/css";
import { Icon } from "@nju/icons";
import { Text, A } from "@nju/ui";
import Link from "next/link";
import * as React from "react";
import { headerMenu, navigationMenu } from "../consts";
import { HoverMenuElement } from "../types";
import { HeaderMenuMobileContentItem } from "./HeaderMenuMobileContentItem";

interface IHeaderMenuMobileContentProps {
  activeMenuElement: HoverMenuElement | null;
  handleToggleActiveMenuElement: (
    hoveredElement: HoverMenuElement | null,
    hiddenOnSecondHover?: boolean
  ) => void;
}

export function HeaderMenuMobileContent({
  activeMenuElement,
  handleToggleActiveMenuElement,
}: IHeaderMenuMobileContentProps) {
  const mapNavigationMenu = navigationMenu.map((item, index) => {
    const isFirst = index === 0;
    const isLast = index === navigationMenu?.length - 1;
    const isOpen = activeMenuElement === item.menuElement;

    const navigationMenuItems = item.menuElement
      ? headerMenu[item.menuElement]?.items
      : [];

    const mapNavigationMenuItems = navigationMenuItems.map(
      (itemMenu, indexMenu) => {
        return (
          <HeaderMenuMobileContentItem
            key={`navigationMenuItem_${indexMenu}`}
            {...itemMenu}
          />
        );
      }
    );

    const titleContent = (
      <div className="relative flex justify-between items-center">
        <Text size="l" weight="bold">
          {item.title}
        </Text>
        {!item?.href && (
          <Icon
            className="mb-[3px]"
            height={24}
            name={isOpen ? "chevron-up" : "chevron-down"}
            tone="neutral"
            width={24}
          />
        )}
        <div
          className={cx(
            "absolute bottom-[-5px] left-0 h-[4px] w-[21px] rounded-[2px] ",
            {
              "bg-ripeLemon": !item?.href && isOpen,
            }
          )}
        />
      </div>
    );

    return (
      <div
        className={cx("relative py-5 border-b-1 border-geyser cursor-pointer", {
          "border-none": isLast,
          "pt-6": isFirst,
        })}
        key={`navigationMenuMobile_${index}`}
        onClick={() => {
          return handleToggleActiveMenuElement(item.menuElement, true);
        }}
      >
        {item?.href && (
          <Link href={item.href} passHref={true}>
            <A display="block" size="inline" variant="neutral">
              {titleContent}
            </A>
          </Link>
        )}
        {!item?.href && titleContent}
        {isOpen && <div>{mapNavigationMenuItems}</div>}
      </div>
    );
  });

  return <>{mapNavigationMenu}</>;
}
