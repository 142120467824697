import { z } from "zod";

const commonOffer = z.object({
  offerId: z.string(),
  state: z.string(),
  priority: z.number(),
  shortFeatures: z.array(
    z.object({
      description: z.object({
        pl: z.string(),
      }),
      icon: z.string(),
      name: z.string(),
      priority: z.number(),
    })
  ),
  longFeatures: z.array(
    z.object({
      title: z.object({
        pl: z.string(),
      }),
      description: z.object({
        pl: z.string(),
      }),
      icon: z.string(),
      name: z.string(),
      priority: z.number(),
    })
  ),
  cardFeatures: z.array(
    z.object({
      description: z.object({
        pl: z.string(),
      }),
      additionalDescription: z
        .object({
          pl: z.string(),
        })
        .optional(),
      icon: z.string(),
      name: z.string(),
      priority: z.number(),
    })
  ),
  extraShortFeatures: z
    .array(
      z.object({
        description: z.object({
          pl: z.string(),
        }),
        icon: z.string(),
        name: z.string(),
        priority: z.number(),
      })
    )
    .optional(),
  discountFeatures: z
    .array(
      z.object({
        description: z.object({
          pl: z.string(),
        }),
        icon: z.string().optional(),
        name: z.string(),
        priority: z.number(),
        additionalDescription: z
          .object({
            pl: z.string(),
          })
          .optional(),
      })
    )
    .optional(),
  paymentFeatures: z
    .array(
      z.object({
        description: z.object({
          pl: z.string(),
        }),
        icon: z.string(),
        name: z.string(),
        priority: z.number(),
      })
    )
    .optional(),
  label: z.string(),
  totalRecurringPrice: z.number(),
  totalOneTimePrice: z.number(),
  totalPrice: z.number(),
  isMvpVersion: z.boolean().optional(),
  isFeatured: z.boolean().optional(),
  ftthSfhPrice: z.number().optional(),
});

//#region FTTH offers
const ftthOffer = commonOffer.extend({
  category: z.literal("FTTH"),
  uploadSpeed: z.number(),
  downloadSpeed: z.number(),
  limited: z.boolean(),
  bundleDiscount: z.number().optional(),
  ftthBundleDiscount: z.number().optional(),
  mobileMultiLineDiscount: z.number().optional(),
  priceDiscountedAfterMobile: z.number().optional(),
  priceDiscountedAfterFtth: z.number().optional(),
  priceDiscountedAfterMobileFtth: z.number().optional(),
  monthlyFeeInHouse: z.number().optional(),
  penaltyFeeModemReturn: z.number().optional(),
});

const loyalty = z.object({
  offerId: z.string(),
  paymentsAmount: z.number(),
  commitment: z.literal(true),
  penaltyFee: z.number(),
});

const indefinite = z.object({
  offerId: z.string(),
  commitment: z.literal(false),
});

export const loyaltyFtthOffer = loyalty.extend(ftthOffer.shape);
export const indefiniteFtthOffer = indefinite.extend(ftthOffer.shape);

export const ftthOffers = z.union([indefiniteFtthOffer, loyaltyFtthOffer]);
//#endregion

export const mobileOffer = commonOffer.extend({
  category: z.literal("MOBILE"),
  dataAllowance: z.number(),
  bundleDiscount: z.number().optional(),
  ftthBundleDiscount: z.number().optional(),
  mobileMultiLineDiscount: z.number().optional(),
  priceDiscountedAfterMobile: z.number().optional(),
  priceDiscountedAfterFtth: z.number().optional(),
  priceDiscountedAfterMobileFtth: z.number().optional(),
});

export const serverResponse = z.array(ftthOffers.or(mobileOffer));
