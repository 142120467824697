import { styled } from "@nju/css";

export type SeparatorProps = React.ComponentProps<typeof Separator>;

export const Separator = styled("hr", {
  border: "none",
  borderTopStyle: "solid",
  p: "$0",
  display: "block",
  width: "100%",
  variants: {
    tone: {
      invisible: {
        borderColor: "transparent",
      },
      soft: {
        borderColor: "$porcelain",
      },
      accent: {
        borderColor: "$cerulean",
      },
      complementary: {
        borderColor: "$geyser",
      },
    },
    thickness: {
      thin: {
        borderTopWidth: "1px",
      },
      medium: {
        borderTopWidth: "2px",
      },
    },
    size: {
      none: {
        my: "$0",
      },
      xxs: {
        my: "$5",
      },
      xs: {
        my: "$5",
        "@fromTablet": {
          my: "$10",
        },
      },
      s: {
        my: "$10",
        "@fromTablet": {
          my: "$15",
        },
      },
      m: {
        my: "$15",
        "@fromTablet": {
          my: "$20",
        },
      },
      l: {
        my: "$20",
        "@fromTablet": {
          my: "$25",
        },
      },
      xl: {
        my: "$25",
        "@fromTablet": {
          my: "$30",
        },
      },
      xxl: {
        my: "$30",
        "@fromTablet": {
          my: "$40",
        },
      },
    },
  },
  defaultVariants: {
    tone: "complementary",
    thickness: "thin",
    size: "xxs",
  },
});
