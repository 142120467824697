import { assign, createMachine } from "xstate";
import { isError } from "@nju/result";
import {
  fetchMaintenance,
  IMaintenance,
} from "@nju/data-access-portal-technical";
import { IScope } from "@nju/scope";

interface IContext {
  orderUnavailableFlag: "1" | "0" | undefined;
  paymentsUnavailableFlag: "1" | "0" | undefined;
  maintenanceFlag: "1" | "0" | undefined;
  maintenanceNWTFlag: "1" | "0" | undefined;
  maintenance: IMaintenance;
  scope: IScope;
}

type IEvent =
  | { type: "MAINTENANCE_RESOLVED"; payload: IMaintenance }
  | { type: "FETCH_MAINTENANCE" }
  | { type: "SYSTEM_ACTIVE" };

export const maintenanceMachine = createMachine(
  {
    schema: {
      context: {} as IContext,
      events: {} as IEvent,
    },
    context: {
      scope: "portal",
      orderUnavailableFlag: undefined,
      paymentsUnavailableFlag: undefined,
      maintenanceFlag: undefined,
      maintenanceNWTFlag: undefined,
      maintenance: {
        orderUnavailable: false,
        paymentsUnavailable: false,
        maintenance: false,
        maintenanceNWT: false,
        messageSellersNWT: undefined,
        identityByTechnicianAllowed: false,
      },
    },
    initial: "fetchMaintenance",
    states: {
      fetchMaintenance: {
        invoke: {
          src: "fetchMaintenance",
        },
        on: {
          SYSTEM_ACTIVE: {
            target: "systemActive",
          },
          MAINTENANCE_RESOLVED: {
            actions: [
              "setMaintenanceFromAPI",
              "resolveMaintenance",
              "resolveMaintenanceNWT",
              "resolveOrderUnavailable",
              "resolvePaymentsUnavailable",
            ],
            target: "resolveMaintenance",
          },
        },
      },
      resolveMaintenance: {
        always: [
          {
            cond: "isMaintenance",
            target: "maintenance",
          },
          {
            cond: "isMaintenanceNWT",
            target: "maintenanceNWT",
          },
          {
            cond: "isSoftMaintenance",
            target: "softMaintenance",
          },
          {
            target: "systemActive",
          },
        ],
      },
      systemActive: {
        entry: "onSystemActive",
        on: {
          FETCH_MAINTENANCE: "fetchMaintenance",
        },
        after: {
          recheckInterval: {
            target: "fetchMaintenance",
          },
        },
      },
      softMaintenance: {
        on: {
          FETCH_MAINTENANCE: {
            target: "fetchMaintenance",
          },
        },
        after: {
          recheckInterval: {
            target: "fetchMaintenance",
          },
        },
      },
      maintenance: {
        entry: "onMaintenance",
        on: {
          FETCH_MAINTENANCE: {
            target: "fetchMaintenance",
          },
        },
        after: {
          recheckInterval: {
            target: "fetchMaintenance",
          },
        },
      },
      maintenanceNWT: {
        entry: "onMaintenanceNWT",
        on: {
          FETCH_MAINTENANCE: {
            target: "fetchMaintenance",
          },
        },
        after: {
          recheckInterval: {
            target: "fetchMaintenance",
          },
        },
      },
    },
  },
  {
    actions: {
      setMaintenanceFromAPI: assign((context, event: IEvent) => {
        if (event.type === "MAINTENANCE_RESOLVED") {
          return {
            maintenance: event.payload,
          };
        }

        return context;
      }),
      resolveMaintenance: assign((context, event: IEvent) => {
        if (event.type === "MAINTENANCE_RESOLVED") {
          const isMaintenanceFlagDisabled =
            context.maintenanceFlag === undefined;
          const maintenanceFromFlag = context.maintenanceFlag === "1";

          return {
            ...context,
            maintenance: {
              ...context.maintenance,
              maintenance: isMaintenanceFlagDisabled
                ? event.payload.maintenance
                : maintenanceFromFlag,
            },
          };
        }

        return context;
      }),
      resolveMaintenanceNWT: assign((context, event: IEvent) => {
        if (event.type === "MAINTENANCE_RESOLVED") {
          const isMaintenanceNWTFlagDisabled =
            context.maintenanceNWTFlag === undefined;
          const maintenanceNWTFromFlag = context.maintenanceNWTFlag === "1";

          return {
            ...context,
            maintenance: {
              ...context.maintenance,
              maintenanceNWT: isMaintenanceNWTFlagDisabled
                ? event.payload.maintenanceNWT
                : maintenanceNWTFromFlag,
            },
          };
        }

        return context;
      }),
      resolveOrderUnavailable: assign((context, event: IEvent) => {
        if (event.type === "MAINTENANCE_RESOLVED") {
          const isOrderUnavailableFlagDisabled =
            context.orderUnavailableFlag === undefined;
          const orderUnavailableFromFlag = context.orderUnavailableFlag === "1";

          return {
            ...context,
            maintenance: {
              ...context.maintenance,
              orderUnavailable: isOrderUnavailableFlagDisabled
                ? event.payload.orderUnavailable
                : orderUnavailableFromFlag,
            },
          };
        }

        return context;
      }),
      resolvePaymentsUnavailable: assign((context, event: IEvent) => {
        if (event.type === "MAINTENANCE_RESOLVED") {
          const isPaymentsUnavailableFlagDisabled =
            context.paymentsUnavailableFlag === undefined;
          const paymentsMaintenanceFromFlag =
            context.paymentsUnavailableFlag === "1";

          return {
            ...context,
            maintenance: {
              ...context.maintenance,
              paymentsUnavailable: isPaymentsUnavailableFlagDisabled
                ? event.payload.paymentsUnavailable
                : paymentsMaintenanceFromFlag,
            },
          };
        }

        return context;
      }),
    },
    delays: {
      recheckInterval: () => {
        return 30_000;
      },
    },
    guards: {
      isMaintenance: ({ maintenance, scope }) => {
        return maintenance.maintenance && scope !== "sales";
      },
      isMaintenanceNWT: ({ maintenance, scope }) => {
        return maintenance.maintenanceNWT && scope === "sales";
      },
      isSoftMaintenance: ({ maintenance }) => {
        return maintenance.orderUnavailable || maintenance.paymentsUnavailable;
      },
    },
    services: {
      fetchMaintenance: () => {
        return async (callback) => {
          const result = await fetchMaintenance();

          if (isError(result)) {
            return callback({ type: "SYSTEM_ACTIVE" });
          }

          return callback({
            type: "MAINTENANCE_RESOLVED",
            payload: result.value,
          });
        };
      },
    },
  }
);
