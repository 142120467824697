import * as React from "react";

export interface IDebugContext {
  [key: string]: unknown;
}

type IRemoveDebugContext = (id: string) => void;
type IAddDebugContext = (id: string, data: unknown) => void;

export const DebugContext = React.createContext<IDebugContext>({});

export const RemoveDebugContext = React.createContext<
  IRemoveDebugContext | undefined
>(undefined);

export const AddDebugContext = React.createContext<
  IAddDebugContext | undefined
>(undefined);

export function useDebug(id: string, state: unknown) {
  const add = React.useContext(AddDebugContext);
  const remove = React.useContext(RemoveDebugContext);

  React.useEffect(() => {
    if (add) {
      add(id, state);
    }

    return () => {
      if (remove) {
        remove(id);
      }
    };
  }, [add, remove, id, state]);
}

export function useDebugContext() {
  const value = React.useContext(DebugContext);

  if (value === null) {
    throw new Error("`Missing DebugContext` in tree.");
  }

  return value;
}
