import { cx } from "@nju/css";
import { Icon } from "@nju/icons";
import { Text } from "@nju/ui";
import Link from "next/link";
import { IHeaderMenuLinksProps } from "../types";

interface IHeaderMenuMobileAccountItemProps {
  isLast: boolean;
}

export function HeaderMenuMobileAccountItem({
  isLast,
  alert,
  link,
  title,
}: IHeaderMenuMobileAccountItemProps & IHeaderMenuLinksProps) {
  return (
    <div
      className={cx("relative py-6 border-b-1 border-geyser cursor-pointer", {
        "border-none": isLast,
      })}
    >
      {alert && (
        <div className="flex gap-2">
          <div className="w-[24px]">
            <Icon height={16} name="info-circle" width={16} />
          </div>
          <div className="pt-[2px]">
            <Text dangerouslySetInnerHTML={{ __html: alert ?? "" }} size="m" />
          </div>
        </div>
      )}
      <Text dangerouslySetInnerHTML={{ __html: title ?? "" }} size="m" />
      {link && (
        <div className="pt-2">
          <Link className="hover:underline text-cerulean" href={link.href}>
            <div className="flex items-center gap-3 justify-start">
              <Text size="m" tone="accent">
                {link.title}
              </Text>
              <Icon height={24} name="arrow-right" tone="accent" width={21} />
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}
