import * as React from "react";
import { getFeatureFlagValue } from "@nju/feature-flags";
import {
  DebugContext,
  AddDebugContext,
  RemoveDebugContext,
} from "./DebugContext";
import { IDebugContext } from "./DebugContext";

interface IDebugProviderProps {
  children: React.ReactNode;
}

export function DebugProvider({ children }: IDebugProviderProps) {
  const [list, setList] = React.useState<IDebugContext>({});

  const add = React.useCallback((id: string, data: unknown) => {
    if (getFeatureFlagValue("debugComponent") === "1") {
      setList((list) => {
        return { ...list, [id]: data };
      });
    }
  }, []);

  const remove = React.useCallback((id: string) => {
    if (getFeatureFlagValue("debugComponent") === "1") {
      setList((list) => {
        delete list[id];
        return list;
      });
    }
  }, []);

  return (
    <AddDebugContext.Provider value={add}>
      <RemoveDebugContext.Provider value={remove}>
        <DebugContext.Provider value={list}>{children}</DebugContext.Provider>
      </RemoveDebugContext.Provider>
    </AddDebugContext.Provider>
  );
}
