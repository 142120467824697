import Bugsnag from "@bugsnag/js";
import { ok } from "@nju/result";
import { client } from "@nju/http-client";
import { serverResponse } from "./validators";
import {
  IFetchProductOffersResult,
  IIndefiniteFtthOffer,
  ILoyaltyFtthOffer,
  IFtthOffer,
  IMobileOffer,
} from "./types";
import { sortByPriority } from "@nju/utils";

export async function fetchProductOffers(): Promise<IFetchProductOffersResult> {
  const result = await client(
    `${process.env.NEXT_PUBLIC_ANONYMOUS_GATEWAY_HOST}/static/nju/offers`,
    {
      validator: serverResponse,
    }
  );

  if (result.isError) {
    Bugsnag.notify(result.err);
    return result;
  }

  const ftthOffers = result.value
    .filter((offer) => {
      return process.env.APP_ENV === "prd"
        ? offer.category === "FTTH" && offer.isMvpVersion === true
        : offer.category === "FTTH";
    })
    .map((offer) => {
      return { ...offer, monthlyFeeInHouse: 20 };
    }) as IFtthOffer[];

  const mobileOffers = result.value.filter((offer) => {
    return process.env.APP_ENV === "prd"
      ? offer.category === "MOBILE" && offer.isMvpVersion === true
      : offer.category === "MOBILE";
  }) as IMobileOffer[];

  const ftthLoyaltyOffers = ftthOffers
    .filter(({ commitment, state, category }) => {
      return (
        commitment === true && state === "PUBLISHED" && category === "FTTH"
      );
    })
    .sort(sortByPriority) as ILoyaltyFtthOffer[];

  const ftthIndefiniteOffers = ftthOffers
    .filter(({ commitment, state, category }) => {
      return (
        commitment === false && state === "PUBLISHED" && category === "FTTH"
      );
    })
    .sort(sortByPriority) as IIndefiniteFtthOffer[];

  const mobileIndefiniteOffers = mobileOffers
    .filter(({ state, category }) => {
      return state === "PUBLISHED" && category === "MOBILE";
    })
    .sort(sortByPriority) as IMobileOffer[];

  return ok({
    archived: result.value
      .filter((offer) => {
        return offer.state === "ARCHIVED";
      })
      .map((offer) => {
        if (offer.category === "FTTH") {
          return offer;
        }
        return {
          ...offer,
          isFreeFromActivationFee: offer.totalOneTimePrice === 0,
        };
      }),
    ftth: {
      loyaltyOffers: ftthLoyaltyOffers,
      indefiniteOffers: ftthIndefiniteOffers,
    },
    mobile: {
      indefiniteOffers: mobileIndefiniteOffers.map((offer) => {
        return {
          ...offer,
          isFreeFromActivationFee: offer.totalOneTimePrice === 0,
        };
      }),
    },
  });
}
