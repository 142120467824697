import { IHeaderMenuItemProps, IHeaderMenuLinksProps } from "../types";
import { HeaderMenuDesktopActiveItems } from "./HeaderMenuDesktopActiveItems";
import { HeaderMenuDesktopActiveAccount } from "./HeaderMenuDesktopActiveAccount";
import { IOfferMode } from "@nju/data-access-portal-offers";

interface IHeaderMenuDesktopActiveProps {
  items: IHeaderMenuItemProps[];
  links: IHeaderMenuLinksProps[];
  mode: IOfferMode;
}

export function HeaderMenuDesktopActive({
  items,
  links,
  mode,
}: IHeaderMenuDesktopActiveProps) {
  return (
    <>
      <HeaderMenuDesktopActiveItems items={items} />
      <HeaderMenuDesktopActiveAccount links={links} mode={mode} />
    </>
  );
}
