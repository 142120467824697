import * as React from "react";
import debug from "debug";
import { useRouter } from "next/router";
import { useMachine } from "@xstate/react";
import { getFeatureFlagValue } from "@nju/feature-flags";
import { MaintenanceContext } from "@nju/data-access-portal-technical";
import { maintenanceMachine } from "./maintenance.machine";
import { portalRoutes, salesRoutes } from "@nju/routes";
import { IScope } from "@nju/scope";

const logger = debug("maintenance");

interface IMaintenanceProps {
  children: React.ReactNode;
  scope: IScope;
  isSellerLoggedIn?: boolean;
}

export function Maintenance({
  children,
  scope,
  isSellerLoggedIn,
}: IMaintenanceProps) {
  logger(`scope = ${scope}`);

  const router = useRouter();
  const [current, send] = useMachine(maintenanceMachine, {
    actions: {
      onSystemActive: () => {
        if (
          scope !== "sales" &&
          router.pathname === portalRoutes.trwajaPrace.path()
        ) {
          router.replace(portalRoutes.index.get());
        }

        if (
          scope === "sales" &&
          router.pathname === salesRoutes.trwajaPrace.get()
        ) {
          router.replace(salesRoutes.index.get());
        }
      },
      onMaintenance: () => {
        if (
          scope !== "sales" &&
          router.pathname !== portalRoutes.trwajaPrace.path() &&
          router.pathname !== portalRoutes.flags.path()
        ) {
          router.replace(portalRoutes.trwajaPrace.get());
        }
      },
      onMaintenanceNWT: () => {
        if (
          scope === "sales" &&
          isSellerLoggedIn &&
          router.pathname !== salesRoutes.trwajaPrace.get() &&
          router.pathname !== salesRoutes.flags.get()
        ) {
          router.replace(salesRoutes.trwajaPrace.get());
        }
      },
    },
    context: {
      scope,
      maintenanceNWTFlag: getFeatureFlagValue("maintenanceNWT"),
      maintenanceFlag: getFeatureFlagValue("maintenance"),
      orderUnavailableFlag: getFeatureFlagValue("orderUnavailable"),
      paymentsUnavailableFlag: getFeatureFlagValue("paymentsUnavailable"),
    },
  });

  const { context } = current;
  const { maintenance } = context;

  logger("%o", maintenance);

  const handleRouteChangeComplete = React.useCallback(() => {
    send({ type: "FETCH_MAINTENANCE" });
  }, [send]);

  React.useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [handleRouteChangeComplete, router.events, scope]);

  return (
    <MaintenanceContext.Provider value={maintenance}>
      {children}
    </MaintenanceContext.Provider>
  );
}
