// remember to update confluence page after modifying this file
// https://dop-orange.atlassian.net/wiki/spaces/WUWA/pages/1827602540/Nju+Tagging+DataLayer

import { IDataLayer } from "./types";

interface IPushSimpleEventProps {
  event: IDataLayer["event"];
  eventName: string;
}

export function pushSimpleEvent({ event, eventName }: IPushSimpleEventProps) {
  if ("dataLayer" in window) {
    window.dataLayer.push({
      event,
      event_name: eventName,
      event_type: "click",
    });
  }
}
