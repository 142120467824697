import {
  BasicError,
  ContractError,
  err,
  HttpError,
  isError,
  ok,
  Result,
  tryGetFromMaybeError,
  UnknownError,
} from "@nju/result";
import {
  IScope,
  ACCESS_TOKEN_SALES_KEY_NAME,
  ACCESS_TOKEN_KEY_NAME,
} from "@nju/scope";
import ky, { HTTPError, Options, TimeoutError } from "ky";
import { TypeOf, ZodTypeAny } from "zod";
import debug from "debug";
import { v1 } from "uuid";

const logger = debug("nju:orchestrator:state");

type IKyParams = Parameters<typeof ky>;
type IInput = IKyParams[0];
type IOptions = IKyParams[1] & {
  forceAddAuth?: boolean;
  validator?: ZodTypeAny;
  doNotRefreshTokenOn401?: boolean;
  scope?: IScope;
};

function canAddCorrelationId(url: string) {
  if (typeof window === "undefined") {
    return false;
  }

  if (
    process.env.NEXT_PUBLIC_IAM_HOST &&
    !url.startsWith(process.env.NEXT_PUBLIC_IAM_HOST)
  ) {
    return true;
  }
  return false;
}

function shouldAddAuthHeader(
  url: string,
  { forceAdd }: { forceAdd?: boolean } = {}
) {
  if (forceAdd) {
    return true;
  }

  if (typeof window === "undefined") {
    return false;
  }

  if (
    process.env.NEXT_PUBLIC_CHAT_HOST &&
    url.startsWith(process.env.NEXT_PUBLIC_CHAT_HOST)
  ) {
    return true;
  }

  if (
    process.env.NEXT_PUBLIC_SURVEY_MOCKS_HOST &&
    url.startsWith(process.env.NEXT_PUBLIC_SURVEY_MOCKS_HOST)
  ) {
    return true;
  }

  if (
    process.env.NEXT_PUBLIC_MOCKS_HOST &&
    url.startsWith(process.env.NEXT_PUBLIC_MOCKS_HOST)
  ) {
    return true;
  }

  if (
    process.env.NEXT_PUBLIC_HELPERS &&
    url.startsWith(process.env.NEXT_PUBLIC_HELPERS)
  ) {
    return true;
  }

  if (
    process.env.NEXT_PUBLIC_PUBLIC_GATEWAY_HOST &&
    url.startsWith(process.env.NEXT_PUBLIC_PUBLIC_GATEWAY_HOST)
  ) {
    return true;
  }

  return false;
}

let customOptions: Partial<Options> = {};

type HookFunctionDefinition =
  | undefined
  | ((scope: IScope) => Promise<Result<BasicError, unknown>> | void);

const hookFunctions: {
  onUnauthorized: HookFunctionDefinition;
  refreshToken: HookFunctionDefinition;
} = {
  onUnauthorized: undefined,
  refreshToken: undefined,
};

interface EnhanceParams {
  hooks?: typeof hookFunctions;
  options?: typeof customOptions;
}

let clientInstance: typeof ky | undefined;

function tryGetJsonFromResponse(body: string) {
  try {
    return JSON.parse(body);
  } catch {
    return;
  }
}

export function create({ hooks, options }: EnhanceParams = {}) {
  if (hooks && hooks.onUnauthorized) {
    hookFunctions.onUnauthorized = hooks.onUnauthorized;
  }
  if (hooks && hooks.refreshToken) {
    hookFunctions.refreshToken = hooks.refreshToken;
  }
  if (options) {
    customOptions = options;
  }

  clientInstance = ky.extend({
    hooks: {
      beforeRequest: [
        (request, options: IOptions) => {
          if (
            shouldAddAuthHeader(request.url, { forceAdd: options.forceAddAuth })
          ) {
            const keyName =
              options.scope === "sales"
                ? ACCESS_TOKEN_SALES_KEY_NAME
                : ACCESS_TOKEN_KEY_NAME;
            request.headers.set(
              "Authorization",
              `Bearer ${window.localStorage.getItem(keyName)}`
            );
          }
        },
      ],
      afterResponse: [
        async function (input, _, response) {
          const url = new URL(input.url);
          if (url.pathname === "/orchestrators") {
            const data = await response.json();
            const orchestrator = Array.isArray(data) ? data[0] : data;

            if (orchestrator) {
              logger(orchestrator.state);
            }
          }
        },
        async (
          request,
          { scope = "portal", doNotRefreshTokenOn401 }: IOptions,
          response
        ) => {
          if (doNotRefreshTokenOn401 === true) {
            return;
          }
          if (
            process.env.NEXT_PUBLIC_APP_ENV !== "prd" &&
            response.status === 403 &&
            scope === "portal"
          ) {
            console.error(
              "UPS SCREEN occurred because maybe you are using token generated from sales app. Try to logout and login again"
            );
          }
          if (response.status === 401 && hookFunctions.refreshToken) {
            const result = await hookFunctions.refreshToken(scope);
            if (result && isError(result)) {
              if (hookFunctions.onUnauthorized) {
                await hookFunctions.onUnauthorized(scope);
              }
              return response;
            }

            const keyName =
              scope === "sales"
                ? ACCESS_TOKEN_SALES_KEY_NAME
                : ACCESS_TOKEN_KEY_NAME;

            request.headers.set(
              "Authorization",
              `Bearer ${window.localStorage.getItem(keyName)}`
            );

            return ky(request);
          }
          return;
        },
      ],
    },
    headers: {
      "Accept-Language": "pl",
    },
    ...customOptions,
  });
}

export const client = async <
  ResponseValue = unknown,
  Options extends IOptions = IOptions,
  ReturnType = Options["validator"] extends ZodTypeAny
    ? TypeOf<Options["validator"]>
    : ResponseValue
>(
  url: IInput,
  options?: Options
): Promise<Result<HttpError | UnknownError | ContractError, ReturnType>> => {
  if (!clientInstance) {
    throw new Error('You must call "create" before using "client"');
  }
  const correlationID = v1();
  try {
    const response = await clientInstance(url, {
      ...options,
      headers: {
        ...options?.headers,
        ...(canAddCorrelationId(url.toString())
          ? { "x-correlation-id": correlationID }
          : {}),
      },
    });

    const data: ReturnType = await (async function () {
      try {
        return await response.json();
      } catch {
        return {};
      }
    })();

    if (options && options.validator) {
      const validated = options.validator.safeParse(data);
      if (!validated.success) {
        return err(
          new ContractError({
            violations: validated.error,
            extra: {
              method: options.method || "get",
              searchParams: options.searchParams || {},
              where: url,
            },
          })
        );
      }

      return ok(validated.data);
    }

    return ok(data);
  } catch (error: unknown) {
    if (error instanceof HTTPError) {
      const body = await error.response.text();
      return err(
        new HttpError({
          message: error.message,
          status: error.response.status,
          extra: {
            error,
            method: options?.method || "get",
            json: tryGetJsonFromResponse(body),
            correlationID,
            body,
            searchParams: options?.searchParams || {},
            where: url,
          },
        })
      );
    }
    if (error instanceof TimeoutError) {
      return err(
        new HttpError({
          message: error.message,
          code: "REQUEST_TIMEOUT",
          status: 0,
          extra: {
            error,
            correlationID,
            method: options?.method || "get",
            searchParams: options?.searchParams || {},
            where: url,
          },
        })
      );
    }

    if (tryGetFromMaybeError(error, "name") === "AbortError") {
      return err(
        new BasicError({
          message: tryGetFromMaybeError(error, "message"),
          code: "REQUEST_ABORTED",
          extra: {
            correlationID,
            method: options?.method || "get",
            searchParams: options?.searchParams || {},
            where: url,
          },
        })
      );
    }
    return err(
      new UnknownError({
        message: tryGetFromMaybeError(error, "message"),
        extra: {
          error,
          extra: {
            correlationID,
            method: options?.method || "get",
            searchParams: options?.searchParams || {},
            where: url,
          },
        },
      })
    );
  }
};
