import * as React from "react";
import { IMaintenance } from "./fetchMaintenance";

export const MaintenanceContext = React.createContext<IMaintenance | undefined>(
  undefined
);

export function useMaintenance() {
  const value = React.useContext(MaintenanceContext);

  if (value === undefined) {
    console.warn("`Missing MaintenanceContext` in tree.");
    return {
      orderUnavailable: false,
      paymentsUnavailable: false,
      maintenance: false,
      maintenanceNWT: false,
      messageSellersNWT: undefined,
    } as IMaintenance;
  }

  return value;
}
