import { useRouter } from "next/router";

import {
  IOffer,
  IMobileOffer,
  IFtthOffer,
  IOfferMode,
} from "./fetchProductOffers/types";

export function isMobileOffer(
  offer: IOffer | undefined
): offer is IMobileOffer {
  if (!offer) {
    return false;
  }

  return offer.category === "MOBILE";
}

export function isFtthOffer(offer: IOffer | undefined): offer is IFtthOffer {
  if (!offer) {
    return false;
  }

  return offer.category === "FTTH";
}

export function useOfferMode(): IOfferMode {
  const router = useRouter();
  const { mode } = router.query;

  return mode === "mobile" ? "mobile" : "ftth";
}

export function useOfferModeWithoutFallback(): IOfferMode | undefined {
  const router = useRouter();
  const { mode } = router.query;

  if (mode === "mobile") {
    return "mobile";
  }

  if (mode === "ftth") {
    return "ftth";
  }

  return undefined;
}

export function modeToWord(mode?: IOfferMode): string {
  if (mode === "mobile") {
    return "mobile";
  }
  if (mode === "ftth") {
    return "światłowód";
  }
  return "subskrypcja";
}
