import * as z from "zod";
import { getContractErrorsList } from "./getContractErrorsList";

/* eslint-disable max-classes-per-file */

type HTTP_ERROR_CODE =
  | "BAD_REQUEST"
  | "UNAUTHORIZED"
  | "PAYMENT_REQUIRED"
  | "FORBIDDEN"
  | "NOT_FOUND"
  | "METHOD_NOT_ALLOWED"
  | "NOT_ACCEPTABLE"
  | "PROXY_AUTHENTICATION_REQUIRED"
  | "REQUEST_TIMEOUT"
  | "CONFLICT"
  | "GONE"
  | "LENGTH_REQUIRED"
  | "PRECONDITION_FAILED"
  | "PAYLOAD_TOO_LARGE"
  | "REQUEST_ENTITY_TOO_LARGE"
  | "URI_TOO_LONG"
  | "REQUEST_URI_TOO_LONG"
  | "UNSUPPORTED_MEDIA_TYPE"
  | "REQUESTED_RANGE_NOT_SATISFIABLE"
  | "EXPECTATION_FAILED"
  | "I_AM_A_TEAPOT"
  | "INSUFFICIENT_SPACE_ON_RESOURCE"
  | "METHOD_FAILURE"
  | "DESTINATION_LOCKED"
  | "UNPROCESSABLE_ENTITY"
  | "LOCKED"
  | "FAILED_DEPENDENCY"
  | "UPGRADE_REQUIRED"
  | "PRECONDITION_REQUIRED"
  | "TOO_MANY_REQUESTS"
  | "REQUEST_HEADER_FIELDS_TOO_LARGE"
  | "INTERNAL_SERVER_ERROR"
  | "NOT_IMPLEMENTED"
  | "BAD_GATEWAY"
  | "SERVICE_UNAVAILABLE"
  | "GATEWAY_TIMEOUT"
  | "HTTP_VERSION_NOT_SUPPORTED"
  | "VARIANT_ALSO_NEGOTIATES"
  | "INSUFFICIENT_STORAGE"
  | "LOOP_DETECTED"
  | "BANDWIDTH_LIMIT_EXCEEDED"
  | "NOT_EXTENDED"
  | "NETWORK_AUTHENTICATION_REQUIRED"
  | "ANOTHER_ORDER_IN_PROGRESS";

export interface Args<T extends string> {
  message: string;
  code?: T;
  name?: string;
  extra?: Record<string, unknown>;
}

function devLog(error: BasicError) {
  if (
    process.env.NODE_ENV === "development" ||
    ["int", "dev"].includes(process.env.NEXT_PUBLIC_APP_ENV || "")
  ) {
    console.groupCollapsed(`${error.name}: ${error.message}`);
    for (const [name, value] of Object.entries(error)) {
      console.log(name, ":", value);
    }
    console.groupEnd();
  }
}

export class BasicError<TCode extends string = string> extends Error {
  message: string;
  code?: TCode;
  extra?: Record<string, unknown>;
  constructor({ message, code, name = "BasicError", extra }: Args<TCode>) {
    super(message);
    this.extra = extra;
    this.name = name;
    this.message = message;
    this.code = code;

    setTimeout(() => {
      devLog(this);
    }, 0);
  }

  extendExtra(data: Record<string, unknown>) {
    this.extra = this.extra ? { ...this.extra, ...data } : data;
  }
}

export class HttpError extends BasicError {
  status: number;

  constructor({
    message,
    status,
    code = "BAD_GATEWAY",
    extra,
  }: Pick<Args<string>, "message" | "extra"> &
    Partial<Pick<Args<HTTP_ERROR_CODE>, "code">> & {
      status: number;
    }) {
    super({ name: "HttpError", message, code, extra });
    this.status = status;
  }
}

export class ContractError extends BasicError {
  key?: string;
  violations?: string[];
  constructor({
    message = "Contract error",
    key,
    code = "CONTRACT_ERROR",
    extra,
    violations,
  }: Pick<Args<string>, "extra"> &
    Partial<Pick<Args<string>, "message" | "code">> & {
      key?: string;
      violations?: z.ZodError;
    }) {
    super({
      name: "ContractError",
      message: key ? `${key} - ${message}` : message,
      code,
      extra,
    });
    this.key = key;
    if (violations) {
      this.violations = getContractErrorsList(violations?.format());
    }
  }
}

export class UnknownError extends BasicError {
  constructor({
    message,
    code = "UNKNOWN_ERROR",
    extra,
  }: Pick<Args<string>, "message" | "extra"> &
    Partial<Pick<Args<string>, "code">>) {
    super({ name: "UnknownError", message, code, extra });
  }
}
