import { css, cx, Variants } from "@nju/css";
import * as React from "react";

export type TextStyleProps = Variants<typeof styles["element"]>;

type IElementType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span";

export type TextProps = TextStyleProps & {
  className?: string;
  testId?: string;
  as?: IElementType;
  dangerouslySetInnerHTML?: {
    __html: string;
  };
};

export function Text({
  size,
  tone,
  weight,
  align,
  font,
  display,
  withUnderline,
  breakLongValue,
  textTransform,
  children,
  className = "",
  testId,
  as = "span",
  dangerouslySetInnerHTML,
}: React.PropsWithChildren<TextProps>) {
  const Element = as;

  return (
    <Element
      /**
       * Temporary solution for class concatenation.
       *
       * @see https://github.com/modulz/stitches/pull/676
       */
      className={cx(
        className.toString(),
        styles
          .element({
            size,
            tone,
            weight,
            align,
            font,
            display,
            withUnderline,
            breakLongValue,
            textTransform,
          })
          .toString()
      )}
      data-cy={testId}
      {...(dangerouslySetInnerHTML
        ? {
            dangerouslySetInnerHTML: dangerouslySetInnerHTML,
          }
        : {})}
    >
      {children}
    </Element>
  );
}

export const styles = {
  element: css({
    variants: {
      size: {
        xxs: {
          fontSize: "$10",
          lineHeight: "$1_3",
        },
        xs: {
          fontSize: "$12",
          lineHeight: "$1_3",
        },
        s: {
          fontSize: "$14",
          lineHeight: "$1_3",
        },
        m: {
          fontSize: "$16",
          lineHeight: "$1_3",
        },
        l: {
          fontSize: "$20",
          lineHeight: "$1_3",
        },
        xl: {
          fontSize: "$24",
          lineHeight: "$1_3",
        },
        keyXS: {
          fontSize: "$18",
          lineHeight: "$1_3",
        },
        keyS: {
          fontSize: "$30",
          lineHeight: "$1",
        },
        keySM: {
          fontSize: "$32",
          lineHeight: "$1",
        },
        keyM: {
          fontSize: "$40",
          lineHeight: "$1",
        },
        keyL: {
          fontSize: "$50",
          lineHeight: "$1",
        },
        keyXL: {
          fontSize: "$70",
          lineHeight: "$1",
        },
        keyXXL: {
          fontSize: "$110",
          lineHeight: "$1",
        },
      },
      display: {
        flex: {
          display: "flex",
        },
        inline: {
          display: "inline",
        },
        block: {
          display: "block",
        },
        inlineBlock: {
          display: "inline-block",
        },
        none: {
          display: "none",
        },
      },
      tone: {
        inherit: {
          color: "currentcolor",
        },
        neutral: {
          color: "$daintree",
        },
        accent: {
          color: "$cerulean",
        },
        secondary: {
          color: "$white",
        },
        info: {
          color: "$ripeLemon",
        },
        note: {
          color: "$iron",
        },
        alert: {
          color: "$tiaMaria",
        },
        disabled: {
          color: "rgba(0,0,0,0.4)",
        },
      },
      weight: {
        regular: {
          fontWeight: "normal",
        },
        bold: {
          fontWeight: "bold",
        },
      },
      align: {
        left: {
          textAlign: "left",
        },
        right: {
          textAlign: "right",
        },
        center: {
          textAlign: "center",
        },
      },
      font: {
        ttfirs: {
          fontFamily: "$ttfirs",
        },
        crocante: {
          fontFamily: "$crocante",
        },
      },
      withUnderline: {
        true: {
          textDecoration: "underline",
        },
        false: {
          textDecoration: "none",
        },
      },
      textTransform: {
        uppercase: {
          textTransform: "uppercase",
        },
        capitalize: {
          textTransform: "capitalize",
        },
      },
      breakLongValue: {
        force: {
          wordBreak: "break-all",
        },
      },
    },
    defaultVariants: {
      align: "left",
      tone: "neutral",
      weight: "regular",
      size: "m",
      font: "ttfirs",
      display: "inline",
    },
  }),
};
