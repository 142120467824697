import { Icon } from "@nju/icons";
import { BasicError } from "@nju/result";
import * as React from "react";
import { Toast, toast } from "react-hot-toast";

export interface IErrorToastProps {
  error: BasicError;
  toast: Toast;
}

export function ErrorToast({ error, toast: currentToast }: IErrorToastProps) {
  const [messageIsExpanded, setMessageIsExpanded] = React.useState(false);
  return (
    <div>
      <div className="flex items-center">
        <div
          onClick={() => {
            setMessageIsExpanded((show) => {
              return !show;
            });
          }}
          style={{ marginRight: 10 }}
        >
          {[error.name, error.code].filter(Boolean).join(" - ")}
        </div>
        <button
          onClick={() => {
            return toast.dismiss(currentToast.id);
          }}
          style={{ all: "unset", display: "revert", cursor: "pointer" }}
        >
          <Icon name="times" />
        </button>
      </div>
      {messageIsExpanded && (
        <div style={{ overflow: "auto", maxHeight: 300, maxWidth: 300 }}>
          <div>
            <pre style={{ fontSize: 10 }}>
              {JSON.stringify(error, undefined, 2)}
            </pre>
          </div>
        </div>
      )}
    </div>
  );
}
