/* eslint-disable unicorn/no-null */
import * as React from "react";
import { BlockContent, Text } from "@nju/ui";
import { cx } from "@nju/css";
import { Logo } from "../shared";
import { portalRoutes } from "@nju/routes";
import { Icon } from "@nju/icons";
import { HeaderMenuDesktopActive } from "./HeaderMenuDesktopActive";
import { HeaderNavigationDesktop } from "./HeaderNavigationDesktop";
import {
  HoverMenuElement,
  IHeaderMenuItemProps,
  IHeaderMenuLinksProps,
} from "../types";
import { RespectMode } from "@nju/feature-portal-orchestrator";

interface IHeaderDesktopProps {
  handleToggleActiveMenuElement: (
    hoveredElement: HoverMenuElement | null,
    hiddenOnSecondHover?: boolean
  ) => void;
  activeMenuElement: HoverMenuElement | null;
  isAccountActive: boolean;
  selectTone: "neutral" | "secondary";
  activeMenu: {
    items: IHeaderMenuItemProps[];
    links: IHeaderMenuLinksProps[];
  };
}

export function HeaderDesktop({
  handleToggleActiveMenuElement,
  activeMenuElement,
  isAccountActive,
  selectTone,
  activeMenu,
}: IHeaderDesktopProps) {
  return (
    <div
      className="w-full"
      onMouseLeave={() => {
        return handleToggleActiveMenuElement(null);
      }}
    >
      <header
        className={cx("flex w-full overflow-hidden items-center py-[26px]", {
          "bg-cerulean": !activeMenuElement,
          "bg-white": activeMenuElement,
        })}
      >
        <div className="flex items-center w-full">
          <div
            className="flex flex-row items-center"
            data-cy="header-navigation-menu"
          >
            <Logo
              href={portalRoutes.index.get()}
              tone={activeMenuElement ? "neutral" : "info"}
            />
            <HeaderNavigationDesktop
              activeMenuElement={activeMenuElement}
              handleToggleActiveMenuElement={handleToggleActiveMenuElement}
            />
          </div>
          <div className="flex ml-auto">
            <div
              className="flex gap-1 items-center cursor-pointer"
              onMouseEnter={() => {
                return handleToggleActiveMenuElement(HoverMenuElement.account);
              }}
            >
              <Icon
                height={32}
                name="user-circle"
                tone={isAccountActive ? "accent" : selectTone}
                width={32}
              />
              <div className="relative">
                <Text
                  tone={isAccountActive ? "accent" : selectTone}
                  weight="bold"
                >
                  moje konto
                </Text>
                <div
                  className={cx(
                    "absolute bottom-[-5px] left-0 h-[4px] w-[21px] rounded-[2px] ",
                    {
                      "bg-cerulean": isAccountActive,
                    }
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      {activeMenuElement && (
        <div className="absolute top-[80px] left-0 right-0 pt-4 pb-8 z-4 bg-white shadow-lg">
          <BlockContent grid="extraWide">
            <RespectMode mode={["ftth", "mobile"]}>
              {({ mode }) => {
                return (
                  <HeaderMenuDesktopActive
                    items={activeMenu.items}
                    links={activeMenu.links}
                    mode={mode}
                  />
                );
              }}
            </RespectMode>
          </BlockContent>
        </div>
      )}
    </div>
  );
}
