import * as React from "react";
import { useHover } from "@react-aria/interactions";
import { styles } from "./styles";
import { Pressable } from "./types";
import { css } from "@nju/css";

export interface AProps extends Pressable {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  isDisabled?: boolean;
  testId?: string;
}

export const A = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<AProps>
>(function A(props, ref) {
  const {
    children,
    size = "m",
    variant = "primary",
    display,
    withUnderline = false,
    className,
    onClick,
    isDisabled,
    testId,
    ...rest
  } = props;

  const { hoverProps, isHovered } = useHover({});

  if (isDisabled) {
    return (
      <span
        className={`${styles.element({
          size,
          variant,
          display,
          withUnderline,
          hover: isHovered,
        })} ${disabledClass} ${className}`}
        data-cy={testId}
        ref={ref}
      >
        {children}
      </span>
    );
  }

  return (
    <span
      className={`${styles.element({
        size,
        variant,
        display,
        withUnderline,
        hover: isHovered,
      })} ${className}`}
      data-cy={testId}
      onClick={onClick}
      ref={ref}
      {...rest}
      {...hoverProps}
    >
      {children}
    </span>
  );
});

const disabledClass = css({
  opacity: "0.5",
  cursor: "not-allowed",
});
