import { z } from "zod";
import {
  USER_FIRST_NAME_PLACEHOLDER,
  USER_LAST_NAME_PLACEHOLDER,
} from "../consts";

export const paymentMethodCreditCard = z.object({
  id: z.string(),
  methodType: z.literal("CREDIT_CARD"),
  active: z.boolean(),
  details: z.object({
    id: z.string(),
    mode: z
      .literal("PRIMARY")
      .or(z.literal("SECONDARY"))
      .or(z.literal("INACTIVE")),
    maskedCardNumber: z.string(),
    expirationMonth: z.string(),
    expirationYear: z.string(),
    issuer: z.string(),
    canRefund: z.boolean(),
    canTopUpDw: z.boolean(),
    canPayRecurrent: z.boolean(),
    canPayOneTime: z.boolean(),
    isOneClickBlik: z.boolean(),
  }),
});

export const paymentMethodDigitalWallet = z.object({
  id: z.string(),
  methodType: z.literal("DIGITAL_WALLET"),
  active: z.boolean(),
  details: z.object({
    id: z.string(),
    mode: z.literal("PRIMARY").or(z.literal("SECONDARY")),
    amount: z.string(),
    canRefund: z.boolean(),
    canTopUpDw: z.boolean(),
    canPayRecurrent: z.boolean(),
    canPayOneTime: z.boolean(),
    isOneClickBlik: z.boolean(),
  }),
});

export const paymentMethodBlik = z.object({
  id: z.string(),
  methodType: z.literal("BLIK"),
  active: z.boolean(),
  details: z.object({
    id: z.string(),
    mode: z.literal("PRIMARY").or(z.literal("SECONDARY")),
    canRefund: z.boolean(),
    canTopUpDw: z.boolean(),
    canPayRecurrent: z.boolean(),
    canPayOneTime: z.boolean(),
    isOneClickBlik: z.boolean(),
  }),
});

export const paymentMethodEkleAccount = z.object({
  id: z.string(),
  methodType: z.literal("BANK_ACCOUNT_EKLE"),
  active: z.boolean(),
  details: z.object({
    id: z.string(),
    mode: z
      .literal("PRIMARY")
      .or(z.literal("SECONDARY"))
      .or(z.literal("INACTIVE"))
      .or(z.literal("HIDDEN")),
    canRefund: z.boolean(),
    canTopUpDw: z.boolean(),
    canPayRecurrent: z.boolean(),
    canPayOneTime: z.boolean(),
    isOneClickBlik: z.boolean(),
  }),
});

export const paymentMethod = z.array(
  z.union([
    paymentMethodCreditCard,
    paymentMethodDigitalWallet,
    paymentMethodBlik,
    paymentMethodEkleAccount,
  ])
);

const typeCode = z.enum([
  "PESEL",
  "PASSPORT",
  "RESIDENCEPERMIT",
  "NATIONALIDCARD",
]);

export const serverResponse = z.object({
  email: z.string(),
  id: z.string(),
  paymentMethod,
  attributes: z.object({
    contactNumber: z.array(z.string()).optional(),
    contactChannels: z.array(z.string()),
  }),
  emailVerified: z.boolean(),
  person: z.object({
    verified: z.boolean().default(false),
    firstName: z.string().default(USER_FIRST_NAME_PLACEHOLDER),
    lastName: z.string().default(USER_LAST_NAME_PLACEHOLDER),
    registrations: z
      .array(
        z.object({
          id: z.string(),
          typeName: z.string(),
          typeCode,
          issuingCountry: z.string().optional(),
        })
      )
      .optional(),
  }),
});

export type IRegistrationDocumentType = z.infer<typeof typeCode>;
