import { generalErrorMessage } from "@nju/utils";
import { Separator } from "../Separator";
import { InfoPanel } from "../InfoPanel";
import { Button } from "../Pressable";
import { IconName } from "@nju/icons";
import { cva } from "@nju/css";

interface IGeneralErrorNotificationProps {
  callback: () => void;
  secondaryCallback?: () => void;
  copy?: string;
  secondaryCopy?: string;
  title?: string;
  description?: string | JSX.Element;
  icon?: IconName;
  buttonsContainerSize?: "normal" | "wide" | "wider";
}

export function GeneralErrorNotification({
  callback,
  secondaryCallback,
  copy = "dalej",
  secondaryCopy,
  title = generalErrorMessage,
  description = "Przejdź dalej i postępuj zgodnie z instrukcjami.",
  icon = "exclamation-circle",
  buttonsContainerSize = "normal",
}: IGeneralErrorNotificationProps) {
  return (
    <div className="flex flex-col items-center pt-5">
      <InfoPanel
        description={description}
        icon={icon}
        testId="msisdn-migration-error-panel"
        title={title}
      >
        <div className={contentCss({ buttonsContainerSize })}>
          <Button
            display="inline"
            onPress={callback}
            size={{ "@onlyMobile": "s" }}
          >
            {copy}
          </Button>
          {secondaryCallback && (
            <Button
              onPress={secondaryCallback}
              size={{ "@onlyMobile": "s" }}
              variant="secondary"
            >
              {secondaryCopy}
            </Button>
          )}
        </div>
      </InfoPanel>
      <Separator tone="invisible" />
      <div className="flex justify-center mt-5">
        <img className="max-w-[300px]" src="/images/pipik_error.svg" />
      </div>
      <Separator size="xl" tone="invisible" />
    </div>
  );
}

const contentCss = cva(
  [
    "flex",
    "flex-row-reverse",
    "gap-2",
    "only-mobile:flex-col",
    "only-mobile:w-full",
  ],
  {
    variants: {
      buttonsContainerSize: {
        normal: "from-tablet:w-[40%], from-tablet:ml-10",
        wide: "from-tablet:w-[65%], from-tablet:ml-10",
        wider: "from-tablet:w-[85%]",
      },
    },
  }
);
