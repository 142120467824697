/**
 * @example
 *  const schema = z.object({ foo: z.string() })
 *  const result = schema.safeParse(1)
 *  if (!result.success) {
 *    console.log(getContractErrorsList(result.error.format()))
 *  }
 */

export function getContractErrorsList(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  parent = "root",
  list: string[] = []
) {
  if ("_errors" in obj) {
    for (const error of obj._errors) {
      list.push([parent, error].join(": "));
    }
  }

  for (const [key, value] of Object.entries(obj)) {
    if (key !== "_errors") {
      getContractErrorsList(value, parent + "." + key, list);
    }
  }

  return list;
}
