import { HeaderMenuMobilePopup } from "./HeaderMenuMobilePopup";
import { HoverMenuElement } from "../types";
import { HeaderMenuMobileContent } from "./HeaderMenuMobileContent";

interface IHeaderMenuMobileProps {
  handleToggleMenuMobileOpen: () => void;
  activeMenuElement: HoverMenuElement | null;
  menuMobileOpen: boolean;
  handleToggleActiveMenuElement: (
    hoveredElement: HoverMenuElement | null,
    hiddenOnSecondHover?: boolean
  ) => void;
}

export function HeaderMenuMobile({
  handleToggleMenuMobileOpen,
  activeMenuElement,
  handleToggleActiveMenuElement,
  menuMobileOpen,
}: IHeaderMenuMobileProps) {
  return (
    <HeaderMenuMobilePopup
      onClose={handleToggleMenuMobileOpen}
      open={menuMobileOpen}
    >
      <HeaderMenuMobileContent
        activeMenuElement={activeMenuElement}
        handleToggleActiveMenuElement={handleToggleActiveMenuElement}
      />
    </HeaderMenuMobilePopup>
  );
}
