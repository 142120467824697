import { BasicError, err, isError, ok, Result } from "@nju/result";
import {
  IScope,
  REFRESH_TOKEN_EXPIRES_SALES_KEY_NAME,
  REFRESH_TOKEN_EXPIRES_KEY_NAME,
  REFRESH_TOKEN_KEY_NAME,
  REFRESH_TOKEN_SALES_KEY_NAME,
  ACCESS_TOKEN_SALES_KEY_NAME,
  ACCESS_TOKEN_KEY_NAME,
  ACCESS_TOKEN_EXPIRES_KEY_NAME,
  ACCESS_TOKEN_EXPIRES_SALES_KEY_NAME,
} from "@nju/scope";
import { loginWithRefreshToken } from "../login";
import { setAuthInfoInStorage } from "../setAuthIntoInStorage";
import { createMachine, interpret } from "xstate";
import debug from "debug";

const logger = debug("maybeRefreshToken");

function getTimeFromToken(name: string) {
  return new Date(
    Number.parseInt(window.localStorage.getItem(name) || "0", 10)
  ).getTime();
}

const machine = createMachine(
  {
    context: {
      scope: "portal",
      loginWithRefreshTokenCanFail: false,
      justCheck: false,
    },
    schema: {
      context: {} as {
        scope: IScope;
        loginWithRefreshTokenCanFail: boolean;
        justCheck: boolean;
      },
      events: {} as { type: "DONE" } | { type: "ERROR" },
    },
    initial: "check",
    states: {
      check: {
        always: [
          {
            cond: "hasAccessToken",
            target: "accessToken",
          },
          {
            target: "refreshToken",
          },
        ],
      },
      accessToken: {
        always: [
          {
            cond: "isAccessTokenExpired",
            target: "refreshToken",
          },
          {
            target: "done",
          },
        ],
      },
      refreshToken: {
        initial: "check",
        states: {
          check: {
            always: [
              {
                cond: "hasRefreshToken",
                target: "validate",
              },
              {
                target: "#unauthorized",
              },
            ],
          },
          validate: {
            always: [
              {
                cond: "isRefreshTokenExpired",
                target: "#unauthorized",
              },
              {
                target: "#unauthorized",
                cond: "justCheck",
              },
              {
                target: "#refreshAccessToken",
              },
            ],
          },
        },
      },
      refreshAccessToken: {
        id: "refreshAccessToken",
        invoke: {
          src: "loginWithRefreshToken",
        },
        on: {
          DONE: {
            target: "done",
          },
          ERROR: {
            target: "unauthorized",
          },
        },
      },
      done: {
        entry: "onSuccess",
      },
      unauthorized: {
        id: "unauthorized",
        entry: "onError",
      },
    },
  },
  {
    guards: {
      justCheck: (context) => {
        return context.justCheck;
      },
      hasAccessToken: ({ scope }) => {
        const accessTokenKeyName =
          scope === "sales"
            ? ACCESS_TOKEN_SALES_KEY_NAME
            : ACCESS_TOKEN_KEY_NAME;
        logger(
          "hasAccessToken",
          !!window.localStorage.getItem(accessTokenKeyName)
        );
        return !!window.localStorage.getItem(accessTokenKeyName);
      },
      hasRefreshToken: ({ scope }) => {
        const refreshTokenKeyName =
          scope === "sales"
            ? REFRESH_TOKEN_SALES_KEY_NAME
            : REFRESH_TOKEN_KEY_NAME;
        logger(
          "hasRefreshToken",
          !!window.localStorage.getItem(refreshTokenKeyName)
        );
        return !!window.localStorage.getItem(refreshTokenKeyName);
      },
      isAccessTokenExpired: ({ scope }) => {
        const accessTokenExpireKeyName =
          scope === "sales"
            ? ACCESS_TOKEN_EXPIRES_SALES_KEY_NAME
            : ACCESS_TOKEN_EXPIRES_KEY_NAME;
        const now = Date.now();
        logger(
          "isAccessTokenExpired",
          now > getTimeFromToken(accessTokenExpireKeyName)
        );
        return now > getTimeFromToken(accessTokenExpireKeyName);
      },
      isRefreshTokenExpired: ({ scope }) => {
        const refreshTokenExpireKeyName =
          scope === "sales"
            ? REFRESH_TOKEN_EXPIRES_SALES_KEY_NAME
            : REFRESH_TOKEN_EXPIRES_KEY_NAME;
        const now = Date.now();
        logger(
          "isRefreshTokenExpired",
          now > getTimeFromToken(refreshTokenExpireKeyName)
        );
        return now > getTimeFromToken(refreshTokenExpireKeyName);
      },
    },
    services: {
      loginWithRefreshToken: ({ scope, loginWithRefreshTokenCanFail }) => {
        return async (callback) => {
          const refreshTokenKeyName =
            scope === "sales"
              ? REFRESH_TOKEN_SALES_KEY_NAME
              : REFRESH_TOKEN_KEY_NAME;
          const refreshToken = window.localStorage.getItem(refreshTokenKeyName);

          if (!refreshToken) {
            throw new Error("Missing refresh token");
          }

          const result = await loginWithRefreshToken({
            refreshToken,
            canFail: loginWithRefreshTokenCanFail,
            scope,
          });

          if (isError(result)) {
            callback({ type: "ERROR" });
          } else {
            setAuthInfoInStorage(result.value, scope);
            callback({ type: "DONE" });
          }
        };
      },
    },
  }
);

interface IMaybeRefreshTokensParams {
  canFail?: boolean;
  scope?: IScope;
  justCheck?: boolean;
}

export async function maybeRefreshTokens({
  canFail,
  scope = "portal",
  justCheck = false,
}: IMaybeRefreshTokensParams): Promise<Result<BasicError, void>> {
  return new Promise((resolve) => {
    const service = interpret(
      machine
        .withContext({
          justCheck,
          scope,
          loginWithRefreshTokenCanFail: !!canFail,
        })
        .withConfig({
          actions: {
            onSuccess: () => {
              logger("onSuccess");
              return resolve(ok(undefined));
            },
            onError: () => {
              logger("onError");
              return resolve(err(new BasicError({ message: "Unauthorized" })));
            },
          },
        })
    );
    service.start();
  });
}
