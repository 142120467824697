import { useUserLoginState } from "@nju/data-access-portal-user";
import { cx } from "@nju/css";
import { Icon } from "@nju/icons";
import { Text } from "@nju/ui";
import Link from "next/link";
import { IHeaderMenuLinksProps } from "../types";

interface IHeaderMenuDesktopActiveItemLinkProps {
  isFirst: boolean;
}

export function HeaderMenuDesktopActiveItemLink({
  isFirst,
  link,
  title,
  alert,
}: IHeaderMenuDesktopActiveItemLinkProps & IHeaderMenuLinksProps) {
  const { isLoggedIn } = useUserLoginState();

  return (
    <div
      className={cx("", {
        "w-1/2 only-tablet:w-[440px]": !isLoggedIn,
        "w-full only-tablet:w-[440px]": isLoggedIn,
        "only-tablet:border-b-1 from-desktop:border-r-1 border-geyser only-tablet:pb-5 from-desktop:pr-5":
          isFirst && !isLoggedIn,
        "only-tablet:border-b-1 from-desktop:border-b-1 border-geyser pb-5":
          isFirst && isLoggedIn,
        "pt-5": !isFirst && isLoggedIn,
        "only-tablet:pt-5 from-desktop:pl-5": !isFirst,
      })}
    >
      {alert && (
        <div className="flex gap-2 only-tablet:pt-6">
          <div className="w-[24px]">
            <Icon height={24} name="info-circle" width={24} />
          </div>
          <div className="max-w-[550px] pt-[2px]">
            <Text dangerouslySetInnerHTML={{ __html: alert ?? "" }} size="l" />
          </div>
        </div>
      )}
      <Text dangerouslySetInnerHTML={{ __html: title ?? "" }} size="l" />
      {link && (
        <div className="pt-2">
          <Link className="hover:underline text-cerulean" href={link.href}>
            <div className="flex items-center gap-3 justify-start">
              <Text size="l" tone="accent">
                {link.title}
              </Text>
              <Icon height={24} name="arrow-right" tone="accent" width={21} />
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}
