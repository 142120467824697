import { IScope } from "./types";

export function getScopeConfig(scope: IScope) {
  if (scope === "portal") {
    return {
      clientId: process.env.NEXT_PUBLIC_CLIENT_ID || "unknown",
      realm: "nju",
    };
  } else {
    return {
      clientId: process.env.NEXT_PUBLIC_CLIENT_ID_SALES || "unknown",
      realm: "sales-agent",
    };
  }

  throw new Error(`Unknown scope ${scope}`);
}
