import { assign, createMachine } from "xstate";
import { IChatInitResponse, init } from "@nju/data-access-portal-chat";
import { isError } from "@nju/result";

type IEvent =
  | { type: "ENABLE" }
  | { type: "DISABLE" }
  | { type: "ERROR" }
  | { type: "OPEN_WIDGET" }
  | {
      type: "INITIALIZED";
      info: IChatInitResponse;
    };

interface IContext {
  orgId: string;
  apiKey: string;
  onClickOpenWidget: () => void;
}

export const chatMachine = createMachine(
  {
    context: {
      orgId: "",
      apiKey: "",
      onClickOpenWidget: () => {
        return;
      },
    },
    schema: {
      context: {} as IContext,
      events: {} as IEvent,
    },
    initial: "disabled",
    states: {
      disabled: {
        id: "disabled",
        on: {
          ENABLE: {
            target: "start",
          },
        },
      },
      start: {
        invoke: {
          src: "init",
        },
        on: {
          DISABLE: {
            actions: "deinitWidget",
            target: "disabled",
          },
          INITIALIZED: {
            actions: "setInitData",
            target: "enabled",
          },
        },
      },
      enabled: {
        initial: "waitForWidget",
        states: {
          waitForWidget: {
            always: {
              target: "ready",
              actions: "initWidget",
              cond: "isReady",
            },
            after: {
              1000: [
                {
                  target: "ready",
                  cond: "isReady",
                },
                {
                  target: "waitForWidget",
                },
              ],
            },
          },
          ready: {
            on: {
              OPEN_WIDGET: {
                actions: "openWidget",
              },
              DISABLE: {
                actions: "deinitWidget",
                target: "#disabled",
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      setInitData: assign<IContext, IEvent>((context, event) => {
        if (event.type === "INITIALIZED") {
          return {
            apiKey: event.info.apiKey,
            orgId: event.info.orgId,
          };
        }
        return context;
      }),
      deinitWidget: () => {
        if ("closer" in window) {
          try {
            // @ts-expect-error missing typing
            window.closer.deinit();
            // eslint-disable-next-line no-empty
          } catch {}
        }
      },
      openWidget: () => {
        if ("closer" in window) {
          try {
            // @ts-expect-error missing typing
            window.closer.openWidget();
            // eslint-disable-next-line no-empty
          } catch {}
        }
      },
      initWidget: ({ orgId, apiKey, onClickOpenWidget }) => {
        if ("closer" in window) {
          try {
            // @ts-expect-error missing typing
            window.closer.init({
              orgId: orgId,
              apiKey: apiKey,
              onOpen: () => {
                onClickOpenWidget();
              },
            });
            // @ts-expect-error missing typing
            window.closer.identify({
              tags: ["nju_dop_www", "nju_dop_brak_seg", "nju_dop_pl"],
            });
            // eslint-disable-next-line no-empty
          } catch {}
        }
      },
    },
    guards: {
      isReady: () => {
        return "closer" in window;
      },
    },
    services: {
      init: () => {
        return async (callback) => {
          const info = await init();

          if (isError(info)) {
            return callback({ type: "ERROR" });
          } else {
            callback({ type: "INITIALIZED", info: info.value });
          }
        };
      },
    },
  }
);
