import * as React from "react";
import { ContractError } from "@nju/result";
import { IFetchProductOffersResponse } from "../fetchProductOffers";
import { IOffer } from "../fetchProductOffers/types";

const Context = React.createContext<IFetchProductOffersResponse | undefined>(
  undefined
);

interface IOfferProviderProps {
  offers: IFetchProductOffersResponse;
}

export function OfferProvider({
  offers,
  children,
}: React.PropsWithChildren<IOfferProviderProps>) {
  return <Context.Provider value={offers}>{children}</Context.Provider>;
}

export function useOffer(
  offerId: string | undefined | null
): IOffer | undefined {
  const value = React.useContext(Context);

  if (value === undefined) {
    throw new ContractError({
      message: "Missing OfferProvider value for useOffer()",
    });
  }

  const offers = [
    ...value.ftth.indefiniteOffers,
    ...value.ftth.loyaltyOffers,
    ...value.mobile.indefiniteOffers,
    ...value.archived,
  ];

  return offers.find((offer) => {
    return offer.offerId === offerId;
  });
}

export function useOffers() {
  const value = React.useContext(Context);

  if (value === undefined) {
    throw new ContractError({
      message: "Missing OfferProvider value for useOffers()",
    });
  }

  return value;
}
