import { navigationMenu } from "../consts";
import { HoverMenuElement } from "../types";
import { HeaderNavigationDesktopItem } from "./HeaderNavigationDesktopItem";

interface IHeaderNavigationDesktopProps {
  activeMenuElement: HoverMenuElement | null;
  handleToggleActiveMenuElement: (
    hoveredElement: HoverMenuElement | null,
    hiddenOnSecondHover?: boolean
  ) => void;
}

export function HeaderNavigationDesktop({
  activeMenuElement,
  handleToggleActiveMenuElement,
}: IHeaderNavigationDesktopProps) {
  const mapNavigationMenu = navigationMenu.map((item, index) => {
    const isActiveMenu = activeMenuElement === item.menuElement;

    return (
      <HeaderNavigationDesktopItem
        activeMenuElement={activeMenuElement}
        handleToggleActiveMenuElement={handleToggleActiveMenuElement}
        isActiveMenu={isActiveMenu}
        item={item}
        key={`HeaderNavigationDesktopItem_${index}`}
      />
    );
  });

  return (
    <div className="pl-8 flex flex-row gap-7 items-center justify-start">
      {mapNavigationMenu}
    </div>
  );
}
