export const ACCESS_TOKEN_KEY_NAME = "access_token";
export const ACCESS_TOKEN_EXPIRES_KEY_NAME = "access_token_expires";
export const REFRESH_TOKEN_KEY_NAME = "refresh_token";
export const REFRESH_TOKEN_EXPIRES_KEY_NAME = "refresh_token_expires";

export const ACCESS_TOKEN_SALES_KEY_NAME = "access_token_sales";
export const ACCESS_TOKEN_EXPIRES_SALES_KEY_NAME = "access_token_expires_sales";
export const REFRESH_TOKEN_SALES_KEY_NAME = "refresh_token_sales";
export const REFRESH_TOKEN_EXPIRES_SALES_KEY_NAME =
  "refresh_token_expires_sales";
