import { INavigationMenuProps } from "../types";
import { Text, A } from "@nju/ui";
import Link from "next/link";
import { useState } from "react";
import { cx } from "@nju/css";
import { HoverMenuElement } from "../types";

interface IHeaderNavigationDesktopItemProps {
  isActiveMenu: boolean;
  item: INavigationMenuProps;
  activeMenuElement: HoverMenuElement | null;
  handleToggleActiveMenuElement: (
    hoveredElement: HoverMenuElement | null,
    hiddenOnSecondHover?: boolean
  ) => void;
}

export function HeaderNavigationDesktopItem({
  isActiveMenu,
  item,
  handleToggleActiveMenuElement,
  activeMenuElement,
}: IHeaderNavigationDesktopItemProps) {
  const [isHover, setIsHover] = useState(false);

  const handleToggleIsHover = (value: boolean) => {
    setIsHover(value);
  };

  const titleContent = (
    <Text
      className="cursor-pointer"
      size="l"
      tone={
        activeMenuElement ? (isActiveMenu ? "accent" : "neutral") : "secondary"
      }
      weight="bold"
    >
      {item.title}
    </Text>
  );
  return (
    <div
      className="relative"
      onMouseEnter={() => {
        handleToggleIsHover(true);
        handleToggleActiveMenuElement(item.menuElement);
      }}
      onMouseLeave={() => {
        return handleToggleIsHover(false);
      }}
    >
      {item?.href && (
        <Link href={item.href} passHref={true}>
          <A size="inline" variant="neutral">
            {titleContent}
          </A>
        </Link>
      )}
      {!item?.href && titleContent}
      <div
        className={cx(
          "absolute bottom-[-5px] left-0 h-[4px] w-[21px] rounded-[2px] ",
          {
            "bg-ripeLemon": isHover && item?.href,
            "bg-cerulean": isActiveMenu && !item?.href,
          }
        )}
      />
    </div>
  );
}
