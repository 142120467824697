/* eslint-disable unicorn/no-null */
import * as React from "react";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { OverlayProvider as AriaOverlayProvider } from "@react-aria/overlays";

interface OverlayContext {
  isOpened: boolean;
  openButtonProps: { onPress: () => void };
  closeButtonProps: { onPress: () => void };
  open(): void;
  close(): void;
}

const OverlayContext = React.createContext<OverlayContext | null>(null);

export function OverlayProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  return <AriaOverlayProvider>{children}</AriaOverlayProvider>;
}

export function useOverlay() {
  const state = useOverlayTriggerState({});
  return React.useMemo(() => {
    return {
      isOpened: state.isOpen,
      close: state.close,
      open: state.open,
      openButtonProps: {
        onPress: state.open,
      },
      closeButtonProps: {
        onPress: state.close,
      },
    };
  }, [state]);
}
