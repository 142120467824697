import * as z from "zod";
import debug from "debug";

const logger = debug("maintenance");

/*
 * see https://dop-orange.atlassian.net/wiki/spaces/DGB/pages/1744339336/ARCH+NJU+Sellers+support
 * */
export const resolveMessageSellersNWT = (messageSellersNWT: string) => {
  try {
    const result = JSON.parse(messageSellersNWT);

    const validator = z.object({
      title: z.string().min(1),
      body: z.string().min(1),
    });

    const validatedResult = validator.safeParse(result);

    logger("%o", validatedResult);

    if (!validatedResult.success) {
      return;
    }

    return validatedResult.data;
  } catch {
    return;
  }
};
