import { Text, A, Badge } from "@nju/ui";
import Link from "next/link";
import { IHeaderMenuItemLinkProps } from "../types";

export function HeaderMenuMobileContentItemLink({
  href,
  title,
  badge,
}: IHeaderMenuItemLinkProps) {
  return (
    <div className="min-h-[27px] flex flex-row gap-1 pl-[39px]">
      <Link href={href} passHref={true}>
        <A size="inline" variant="neutral">
          <Text size="l" tone="neutral">
            {title}
          </Text>
        </A>
      </Link>
      {badge && (
        <Badge
          backgroundCerulean={badge.tone === "accent"}
          badgeText={badge?.title}
          small={true}
        />
      )}
    </div>
  );
}
