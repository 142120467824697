import Bugsnag from "@bugsnag/js";
import { client } from "@nju/http-client";
import { isError, ok } from "@nju/result";
import { IFetchMaintenanceResult } from "./types";
import { serverResponse } from "./validators";
import { resolveMessageSellersNWT } from "./resolveMessageSellerNWT";

export async function fetchMaintenance(): Promise<IFetchMaintenanceResult> {
  const result = await client(
    `${process.env.NEXT_PUBLIC_ANONYMOUS_GATEWAY_HOST}/static/nju/remoteconfig`,
    {
      headers: {
        apikey: process.env.NEXT_PUBLIC_APIKEY || "unknown",
        "accept-language": "pl",
      },
      validator: serverResponse,
    }
  );

  if (isError(result)) {
    Bugsnag.notify(result.err);
    return result;
  }

  const {
    maintenance,
    maintenanceNWT,
    identityByTechnicianAllowed,
    paymentsUnavailable,
    orderUnavailable,
    messageSellersNWT,
  } = result.value.parameters;

  return ok({
    maintenance: maintenance.defaultValue.value === "true",
    maintenanceNWT: maintenanceNWT.defaultValue.value === "true",
    identityByTechnicianAllowed:
      identityByTechnicianAllowed.defaultValue.value === "true",
    paymentsUnavailable: paymentsUnavailable.defaultValue.value === "true",
    orderUnavailable: orderUnavailable.defaultValue.value === "true",
    messageSellersNWT: resolveMessageSellersNWT(
      messageSellersNWT.defaultValue.value
    ),
  });
}
