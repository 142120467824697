import * as z from "zod";

const maintenanceDetails = z.object({
  defaultValue: z.object({
    value: z.string(),
  }),
});

export const serverResponse = z.object({
  parameters: z.object({
    maintenance: maintenanceDetails,
    paymentsUnavailable: maintenanceDetails,
    identityByTechnicianAllowed: maintenanceDetails,
    orderUnavailable: maintenanceDetails,
    maintenanceNWT: maintenanceDetails,
    messageSellersNWT: maintenanceDetails,
  }),
});
