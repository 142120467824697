import { Icon } from "@nju/icons";
import { Text } from "@nju/ui";
import { HeaderMenuMobileContentItemLink } from "./HeaderMenuMobileContentItemLink";
import { IHeaderMenuItemProps } from "../types";

export function HeaderMenuMobileContentItem({
  icon,
  links,
  title,
}: IHeaderMenuItemProps) {
  const mapLinks = links.map((itemLink, indexLink) => {
    return (
      <HeaderMenuMobileContentItemLink
        key={`headerMenuItemLink_${indexLink}`}
        {...itemLink}
      />
    );
  });

  return (
    <div>
      <div className="flex gap-3 items-center justify-start pt-5 pb-4">
        <Icon
          data-cy="agent-menu-button"
          height={24}
          name={icon}
          tone="accent"
          width={24}
        />
        <Text size="l" tone="accent">
          {title}
        </Text>
      </div>
      <div className="flex flex-col gap-4 flex-wrap h-full">{mapLinks}</div>
    </div>
  );
}
