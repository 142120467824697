import { z } from "zod";

export const serverResponse = z.object({
  access_token: z.string(),
  expires_in: z.number(),
  "not-before-policy": z.number(),
  refresh_expires_in: z.number(),
  refresh_token: z.string(),
  scope: z.string(),
  session_state: z.string(),
  token_type: z.string(),
});
