import Bugsnag from "@bugsnag/js";
import {
  ACCESS_TOKEN_EXPIRES_KEY_NAME,
  ACCESS_TOKEN_KEY_NAME,
  REFRESH_TOKEN_EXPIRES_KEY_NAME,
  REFRESH_TOKEN_KEY_NAME,
} from "@nju/scope";

export function logout() {
  Bugsnag.setUser(undefined, undefined, undefined);
  window.localStorage.removeItem(ACCESS_TOKEN_KEY_NAME);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY_NAME);
  window.localStorage.removeItem(ACCESS_TOKEN_EXPIRES_KEY_NAME);
  window.localStorage.removeItem(REFRESH_TOKEN_EXPIRES_KEY_NAME);
}
