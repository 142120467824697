import * as React from "react";

const Context = React.createContext({ spritePath: "" });

interface IconProviderProps {
  spritePath: string;
}

export const IconProvider = ({
  children,
  spritePath,
}: React.PropsWithChildren<IconProviderProps>) => {
  const value = React.useMemo(() => {
    return {
      spritePath,
    };
  }, [spritePath]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useIconProvider() {
  return React.useContext(Context);
}
