import { css, Variants } from "@nju/css";
import * as React from "react";
import { handleAddMinHeightToSection } from "./utils";
import { useWindowSize } from "@nju/utils";

type BlockContentStyleProps = Variants<typeof styles["wrapper"]> &
  Variants<typeof styles["content"]>;

export type BlockContentProps = BlockContentStyleProps;

export function BlockContent({
  tone = "none",
  grid = "default",
  rounded = "none",
  spacing,
  spacingRow = "default",
  children,
  overflow,
  border,
}: React.PropsWithChildren<BlockContentProps>) {
  const { height, width } = useWindowSize();

  React.useEffect(() => {
    handleAddMinHeightToSection();
  }, [height, width]);

  return (
    <section
      className={styles.wrapper({ tone, spacing, rounded, overflow, border })}
    >
      <div className={styles.content({ grid, spacingRow })}>{children}</div>
    </section>
  );
}

const styles = {
  wrapper: css({
    width: "100%",
    variants: {
      overflow: {
        y: {
          overflowY: "hidden",
        },
        x: {
          overflowX: "hidden",
        },
        true: {
          overflow: "hidden",
        },
      },
      tone: {
        primary: {
          backgroundColor: "$porcelain",
        },
        secondary: {
          backgroundColor: "$white",
        },
        accent: {
          backgroundColor: "$cerulean",
          color: "$white",
        },
        gray: {
          backgroundColor: "$porcelain",
        },
        none: {},
      },
      spacing: {
        none: {
          py: "$0",
        },
        xxs: {
          py: "$5",
        },
        xs: {
          py: "$10",
        },
        s: {
          py: "$15",
        },
        m: {
          py: "$20",
        },
        l: {
          py: "$30",
        },
        xl: {
          py: "$40",
        },
        xxl: {
          py: "$60",
        },
        xxxl: {
          py: "$80",
        },
      },
      rounded: {
        none: {
          borderRadius: "$0",
        },
        primary: {
          borderRadius: "$12",
        },
      },
      border: {
        primary: {
          border: "2px solid $geyser",
        },
        accent: {
          border: "2px solid $cerulean",
        },
        none: {
          border: "none",
        },
      },
    },
    defaultVariants: {
      tone: "none",
      spacing: "none",
      rounded: "none",
      border: "none",
    },
  }),
  content: css({
    mx: "$auto",
    height: "100%",
    boxSizing: "content-box",
    variants: {
      grid: {
        default: {
          maxWidth: "$maxGridWidth",
        },
        extraNarrow: {
          maxWidth: "$maxGridWidth",
          "@fromTablet": {
            maxWidth: 720,
          },
        },
        narrow: {
          maxWidth: "$maxGridWidth",
          "@fromTablet": {
            maxWidth: 820,
          },
        },
        wide: {
          maxWidth: "$maxGridWidth",
          "@fromTablet": {
            maxWidth: 960,
          },
        },
        extraWide: {
          maxWidth: "$maxGridHeaderWidth",
        },
      },
      spacingRow: {
        default: {
          px: "$15",
          "@fromTablet": {
            px: "$30",
          },
        },
        header: {
          px: "$30",
        },
        none: {
          px: "$0",
        },
      },
    },
    defaultVariants: {
      grid: "default",
    },
  }),
};
