import Bugsnag from "@bugsnag/js";
import { client } from "@nju/http-client";
import { isError, ok } from "@nju/result";
import {
  IInfoResult,
  IPaymentMethod,
  IPaymentMethodDigitalWallet,
} from "./types";
import { serverResponse } from "./validators";

export async function info(): Promise<IInfoResult> {
  const result = await client(
    `${process.env.NEXT_PUBLIC_PUBLIC_GATEWAY_HOST}/users`,
    {
      validator: serverResponse,
      headers: {
        "Accept-Language": "pl",
        "Client-Id": process.env.NEXT_PUBLIC_CLIENT_ID || "unknown",
      },
    }
  );

  if (isError(result)) {
    Bugsnag.notify(result.err);
    return result;
  }

  const primaryPaymentMethod = result.value.paymentMethod.find((payment) => {
    return payment.details.mode === "PRIMARY";
  }) as IPaymentMethod | undefined;

  const availablePaymentMethods = result.value.paymentMethod
    .filter(({ methodType, active }) => {
      return methodType !== "BLIK" && active; // BLIK method payment is omitted
    })
    .sort((_, { details }) => {
      return details.mode === "PRIMARY" ? 1 : -1;
    });

  const hasCreditCard = availablePaymentMethods.some(({ methodType }) => {
    return methodType === "CREDIT_CARD";
  });

  const previouslyPaidWithBlik = result.value.paymentMethod.some(
    ({ methodType, details }) => {
      return (
        methodType === "BLIK" &&
        ["PRIMARY", "SECONDARY"].includes(details.mode) &&
        details.isOneClickBlik
      );
    }
  );

  const digitalWalletPaymentMethod = result.value.paymentMethod.find(
    ({ methodType }) => {
      return methodType === "DIGITAL_WALLET";
    }
  ) as IPaymentMethodDigitalWallet | undefined;

  return ok({
    id: result.value.id,
    email: result.value.email,
    firstName: result.value.person.firstName,
    lastName: result.value.person.lastName,
    isPersonVerified: result.value.person.verified,
    primaryPaymentMethod,
    availablePaymentMethods,
    hasCreditCard,
    previouslyPaidWithBlik,
    attributes: result.value.attributes,
    isEmailVerified: result.value.emailVerified,
    currentFunds: Number.parseFloat(
      digitalWalletPaymentMethod?.details?.amount || "0"
    ),
    person: result.value.person,
  });
}
