import * as React from "react";
import { useRouter } from "next/router";
import { portalRoutes } from "@nju/routes";
import {
  IOfferMode,
  useOfferModeWithoutFallback,
} from "@nju/data-access-portal-offers";

export function RespectMode({
  mode,
  ...props
}: {
  mode: IOfferMode | IOfferMode[];
  children:
    | React.ReactChild
    | ((props: { mode: IOfferMode }) => React.ReactChild);
}) {
  const router = useRouter();
  const currentMode = useOfferModeWithoutFallback();
  const [isOk, setIsOk] = React.useState(false);
  const [currentModeWithFallback, setCurrentModeWithFallback] =
    React.useState<IOfferMode>();

  React.useEffect(() => {
    let resolvedMode: IOfferMode | undefined = currentMode;

    if (!resolvedMode) {
      resolvedMode =
        (window.sessionStorage.getItem(
          "onboardingMode"
        ) as IOfferMode | null) || "ftth";
    }

    setCurrentModeWithFallback(resolvedMode);

    if (
      Array.isArray(mode) ? mode.includes(resolvedMode) : mode === resolvedMode
    ) {
      setIsOk(true);
    } else {
      router.push(portalRoutes.mojeKonto.index.get({ mode: resolvedMode }));
    }

    return () => {
      if (resolvedMode) {
        window.sessionStorage.setItem("onboardingMode", resolvedMode);
      }
    };
  }, [currentMode, mode, router]);

  return isOk && currentModeWithFallback ? (
    <>
      {typeof props.children === "function"
        ? props.children({ ...props, mode: currentModeWithFallback })
        : props.children}
    </>
  ) : // eslint-disable-next-line unicorn/no-null
  null;
}
