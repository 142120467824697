import { z } from "zod";
import { ContractError, err, Result } from "@nju/result";
import { ok } from "@nju/result";

type KeysOfUnion<T> = T extends T ? keyof T : never;

export function route(pathname: string): {
  get: () => string;
  path: () => string;
  input: undefined;
  hasParam: undefined;
};
export function route<TInput extends z.ZodType = z.ZodType>(
  pathname: string,
  input: TInput
): {
  get: z.output<TInput> extends Exclude<z.output<TInput>, undefined>
    ? (params: z.output<TInput>) => string
    : (params?: z.output<TInput>) => string;
  path: () => string;
  input: (value: unknown) => Result<ContractError, z.output<TInput>>;
  hasParam: (
    from: unknown,
    name: KeysOfUnion<z.output<TInput>>,
    value?: unknown
  ) => boolean;
};
export function route<TInput extends z.ZodType = z.ZodType>(
  pathname: string,
  input?: TInput
) {
  const get = (params: z.output<TInput>) => {
    if (params) {
      const searchParams = new URLSearchParams(params);
      return `${pathname}?${searchParams.toString()}`;
    }
    return pathname;
  };
  const check = input
    ? (value: unknown) => {
        const result = input.safeParse(value);
        if (result.success) {
          return ok(result.data);
        }
        return err(
          new ContractError({
            message: "invalid input params",
            violations: result.error,
          })
        );
      }
    : undefined;

  const hasParam = input
    ? (from: unknown, name: keyof z.output<TInput>, value?: unknown) => {
        const result = input.safeParse(from);
        if (!result.success) {
          return false;
        }
        if (value !== undefined) {
          return result.data[name] === value;
        }
        return name in result.data;
      }
    : undefined;

  const path = () => {
    return pathname;
  };

  return { get, input: check, hasParam, path };
}
