import Cookies from "js-cookie";
import { FeatureFlag } from "./types";

const FEATURE_FLAG_PREFIX = "nju_flag_";

export function setFeatureFlagValue(name: FeatureFlag, value: "0" | "1") {
  Cookies.set(`${FEATURE_FLAG_PREFIX}_${name}`, value, {
    expires: 365,
  });
}

export function getFeatureFlagValue(name: FeatureFlag): "0" | "1" | undefined {
  const value = Cookies.get(`${FEATURE_FLAG_PREFIX}_${name}`);
  if (value && ["1", "0"].includes(value)) {
    // @ts-expect-error wrong return type, but it's ok.
    return value;
  }
  return undefined;
}

export function setFeatureValue(name: FeatureFlag, value: string) {
  Cookies.set(`${FEATURE_FLAG_PREFIX}_${name}`, value, {
    expires: 365,
  });
}

export function getFeatureValue(name: FeatureFlag): string | undefined {
  const value = Cookies.get(`${FEATURE_FLAG_PREFIX}_${name}`);

  if (value) {
    return value;
  }

  return undefined;
}

export function unsetFeatureFlag(name: FeatureFlag) {
  return Cookies.remove(`${FEATURE_FLAG_PREFIX}_${name}`);
}
