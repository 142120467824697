export const handleAddMinHeightToSection = () => {
  const header = document.querySelector("header");
  const footer = document.querySelector("footer");
  const sections = document.querySelectorAll("section");
  const headerHeight = header?.offsetHeight ?? 0;
  const footerHeight = footer?.offsetHeight ?? 0;

  const filteredSections = [...sections].filter((section) => {
    return !header?.contains(section) && !footer?.contains(section);
  });

  if (filteredSections.length === 0) {
    return;
  }

  if (filteredSections.length > 1) {
    for (const item of filteredSections) {
      const sectionItemElement = item as HTMLElement;
      sectionItemElement.style.minHeight = "";
    }
    return;
  }

  const sectionElement = filteredSections[0] as HTMLElement;

  sectionElement.style.minHeight = `calc(100dvh - ${headerHeight}px - ${footerHeight}px)`;
};
