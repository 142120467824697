/* eslint-disable unicorn/no-null */
import * as React from "react";
import { useUserLoginState } from "@nju/data-access-portal-user";
import { BlockContent, Button } from "@nju/ui";
import { cx } from "@nju/css";
import { Logo } from "../shared";
import { portalRoutes } from "@nju/routes";
import { Icon } from "@nju/icons";
import { HeaderMenuMobile } from "./HeaderMenuMobile";
import { HeaderMenuMobileAccount } from "./HeaderMenuMobileAccount";
import { HoverMenuElement } from "../types";
import { RespectMode } from "@nju/feature-portal-orchestrator";

interface IHeaderMobileProps {
  handleToggleActiveMenuElement: (
    hoveredElement: HoverMenuElement | null,
    hiddenOnSecondHover?: boolean
  ) => void;
  activeMenuElement: HoverMenuElement | null;
  selectTone: "neutral" | "secondary";
  width: number;
}

export function HeaderMobile({
  width,
  activeMenuElement,
  selectTone,
  handleToggleActiveMenuElement,
}: IHeaderMobileProps) {
  const [menuMobileOpen, setMenuMobileOpen] = React.useState(false);
  const [menuMobileAccountOpen, setMenuMobileAccountOpen] =
    React.useState(false);

  const { isLoggedIn } = useUserLoginState();

  React.useEffect(() => {
    setMenuMobileOpen(false);
    setMenuMobileAccountOpen(false);
  }, [width]);

  React.useEffect(() => {
    document.body.style.overflowY =
      menuMobileOpen || menuMobileAccountOpen ? "hidden" : "visible";
  }, [menuMobileOpen, menuMobileAccountOpen]);

  const handleToggleMenuMobileOpen = () => {
    setMenuMobileOpen((prevState) => {
      return !prevState;
    });
  };

  const handleToggleMenuMobileAccountOpen = () => {
    setMenuMobileAccountOpen((prevState) => {
      return !prevState;
    });
  };

  return (
    <>
      <header
        className={cx("flex w-full overflow-hidden items-center bg-cerulean", {
          "bg-cerulean": !activeMenuElement,
        })}
      >
        <BlockContent spacing="none">
          <div className="flex items-center py-[26px]">
            <div
              className={cx("flex flex-row", {
                "flex-initial w-full": isLoggedIn,
                "flex-1": !isLoggedIn,
              })}
            >
              <Logo href={portalRoutes.index.get()} tone="info" />
              <div className="flex justify-end w-full gap-4 items-center">
                <div className="cursor-pointer">
                  <Button
                    onPress={handleToggleMenuMobileAccountOpen}
                    size="inline"
                    variant="simple"
                  >
                    <Icon
                      height={32}
                      name="user-circle"
                      tone="secondary"
                      width={32}
                    />
                  </Button>
                </div>
                <Button
                  onPress={handleToggleMenuMobileOpen}
                  size="inline"
                  variant="simple"
                >
                  <Icon
                    className="mb-[3px]"
                    height={18}
                    name="bars-bold"
                    tone={selectTone}
                    width={22}
                  />
                </Button>
              </div>
            </div>
          </div>
        </BlockContent>
      </header>
      <HeaderMenuMobile
        activeMenuElement={activeMenuElement}
        handleToggleActiveMenuElement={handleToggleActiveMenuElement}
        handleToggleMenuMobileOpen={handleToggleMenuMobileOpen}
        menuMobileOpen={menuMobileOpen}
      />
      <RespectMode mode={["ftth", "mobile"]}>
        {({ mode }) => {
          return (
            <HeaderMenuMobileAccount
              handleToggleMenuMobileAccountOpen={
                handleToggleMenuMobileAccountOpen
              }
              menuMobileAccountOpen={menuMobileAccountOpen}
              mode={mode}
            />
          );
        }}
      </RespectMode>
    </>
  );
}
