import { css, Variants } from "@nju/css";
import { useIconProvider } from "./IconProvider";

import { IconName } from "./types";

interface IconOwnProps {
  name: IconName;
  className?: string;
  width?: number;
  height?: number;
}

export type IconStyleProps = Variants<typeof styles["element"]>;

export type IconProps = IconOwnProps & IconStyleProps;

export function Icon({ name, tone, className, ...rest }: IconProps) {
  const { spritePath } = useIconProvider();

  return (
    <svg
      height="1em"
      width="1em"
      {...rest}
      className={`${className} ${styles.element({ tone })}`}
    >
      <use
        style={{ fill: "currentColor" }}
        xlinkHref={`${spritePath}#${name}`}
      />
    </svg>
  );
}

const styles = {
  element: css({
    variants: {
      tone: {
        info: {
          color: "$ripeLemon",
        },
        complementary: {
          color: "$geyser",
        },
        secondary: {
          color: "$white",
        },
        neutral: {
          color: "$daintree",
        },
        accent: {
          color: "$cerulean",
        },
        alert: {
          color: "$tiaMaria",
        },
        none: {
          color: "transparent",
        },
      },
    },
  }),
};
