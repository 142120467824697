import { Icon, IconName } from "@nju/icons";
import { cx } from "@nju/css";

export interface IBadgeProps {
  badgeText: string;
  name?: IconName;
  small?: boolean;
  backgroundCerulean?: boolean;
}

export function Badge({
  badgeText,
  name,
  small,
  backgroundCerulean,
}: IBadgeProps) {
  return (
    <div className="flex">
      <div
        className={cx(
          "bg-cerulean rounded-badge flex items-center z-3 gap-1 ",
          {
            "py-1 px-3 text-size-16": !small,
            "py-0 px-2 text-size-14": small,
            "bg-ripeLemon text-black": !backgroundCerulean,
            "bg-cerulean text-white": backgroundCerulean,
          }
        )}
      >
        {name && <Icon height={20} name={name} width={20} />}
        <span>{badgeText}</span>
      </div>
    </div>
  );
}
