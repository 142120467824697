import { css, Variants } from "@nju/css";

type ILoaderStyleProps = Variants<typeof loader>;

interface ILoaderOwnProps {
  className?: string;
  size?: string;
}

interface ILoaderProps extends ILoaderOwnProps, ILoaderStyleProps {}

export function Loader({ className, tone, size = "24" }: ILoaderProps) {
  return (
    <svg
      aria-label="Trwa ładowanie"
      className={`${className} ${loader({ tone })}`}
      display="block"
      height={size}
      preserveAspectRatio="xMidYMid"
      style={{ background: "0 0" }}
      viewBox="0 0 100 100"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        r="40"
        strokeDasharray="62.83185307179586 62.83185307179586"
        strokeLinecap="round"
        strokeWidth="8"
      >
        <animateTransform
          attributeName="transform"
          dur="1.1111111111111112s"
          keyTimes="0;1"
          repeatCount="indefinite"
          type="rotate"
          values="0 50 50;360 50 50"
        />
      </circle>
    </svg>
  );
}

const loader = css({
  stroke: "$cerulean",
  variants: {
    tone: {
      dark: {
        stroke: "$daintree",
      },
    },
  },
});
