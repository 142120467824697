import Bugsnag from "@bugsnag/js";
import { client } from "@nju/http-client";
import { IChatInitResult } from "./types";
import { serverResponse } from "./validators";
import { isError } from "@nju/result";

export async function init(): Promise<IChatInitResult> {
  const result = await client(
    `${process.env.NEXT_PUBLIC_PUBLIC_GATEWAY_HOST}/users/chat`,
    {
      method: "post",
      validator: serverResponse,
      doNotRefreshTokenOn401: true,
    }
  );

  if (isError(result)) {
    Bugsnag.notify(result.err);
    return result;
  }

  return result;
}
