import { IHeaderMenuItemProps } from "../types";
import { HeaderMenuDesktopActiveItem } from "./HeaderMenuDesktopActiveItem";

interface IHeaderMenuDesktopActiveItemsProps {
  items: IHeaderMenuItemProps[];
}

export function HeaderMenuDesktopActiveItems({
  items,
}: IHeaderMenuDesktopActiveItemsProps) {
  const mapHeaderItems = items?.map((item, index) => {
    return (
      <HeaderMenuDesktopActiveItem
        key={`headerItem_${index}`}
        {...item}
        isFirst={index === 0}
        isLast={index === items.length - 1}
        oddItem={index % 2 === 1}
      />
    );
  });

  return <div className="flex flex-wrap gap-y-5">{mapHeaderItems}</div>;
}
