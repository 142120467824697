export const copyToClipboard = async (data: string) => {
  try {
    await navigator.clipboard.writeText(data);

    return "Skopiowano do schowka";
  } catch (error) {
    console.error("Copy to clipboard error occured", error);
    return "Wystąpił błąd, spróbuj ponownie";
  }
};
