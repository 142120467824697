import {
  IScope,
  ACCESS_TOKEN_EXPIRES_KEY_NAME,
  ACCESS_TOKEN_EXPIRES_SALES_KEY_NAME,
  ACCESS_TOKEN_KEY_NAME,
  ACCESS_TOKEN_SALES_KEY_NAME,
  REFRESH_TOKEN_EXPIRES_KEY_NAME,
  REFRESH_TOKEN_EXPIRES_SALES_KEY_NAME,
  REFRESH_TOKEN_KEY_NAME,
  REFRESH_TOKEN_SALES_KEY_NAME,
} from "@nju/scope";
import { ILoginResponse } from "./login";

export function setAuthInfoInStorage(data: ILoginResponse, scope: IScope) {
  if (scope === "sales") {
    window.localStorage.setItem(ACCESS_TOKEN_SALES_KEY_NAME, data.accessToken);
    window.localStorage.setItem(
      REFRESH_TOKEN_SALES_KEY_NAME,
      data.refreshToken
    );
    window.localStorage.setItem(
      ACCESS_TOKEN_EXPIRES_SALES_KEY_NAME,
      data.accessTokenExpirationDate.toString()
    );
    window.localStorage.setItem(
      REFRESH_TOKEN_EXPIRES_SALES_KEY_NAME,
      data.refreshTokenExpirationDate.toString()
    );
  } else {
    window.localStorage.setItem(ACCESS_TOKEN_KEY_NAME, data.accessToken);
    window.localStorage.setItem(REFRESH_TOKEN_KEY_NAME, data.refreshToken);
    window.localStorage.setItem(
      ACCESS_TOKEN_EXPIRES_KEY_NAME,
      data.accessTokenExpirationDate.toString()
    );
    window.localStorage.setItem(
      REFRESH_TOKEN_EXPIRES_KEY_NAME,
      data.refreshTokenExpirationDate.toString()
    );
  }
}
