/* eslint-disable unicorn/no-null */
import { HeaderDesktop } from "../desktop";
import { HeaderMobile } from "../mobile";
import * as React from "react";
import { useWindowSize } from "@nju/utils";
import { HoverMenuElement } from "../types";
import { headerMenu } from "../consts";
import { BlockContent } from "@nju/ui";

export function Header() {
  const [activeMenuElement, setActiveMenuElement] =
    React.useState<null | HoverMenuElement>(null);
  const { width } = useWindowSize();

  const activeMenu = headerMenu[`${activeMenuElement}`];
  const isAccountActive = activeMenuElement === HoverMenuElement.account;
  const selectTone = activeMenuElement ? "neutral" : "secondary";

  React.useEffect(() => {
    setActiveMenuElement(null);
  }, [width]);

  const handleToggleActiveMenuElement = (
    hoveredElement: HoverMenuElement | null,
    hiddenOnSecondHover?: boolean
  ) => {
    setActiveMenuElement((prevState) => {
      if (hiddenOnSecondHover) {
        return prevState === hoveredElement ? null : hoveredElement;
      } else {
        return hoveredElement;
      }
    });
  };

  return (
    <BlockContent
      grid="extraWide"
      tone={activeMenuElement ? "secondary" : "accent"}
    >
      <div className="only-mobile:hidden" data-cy="header-user-menu">
        <HeaderDesktop
          activeMenu={activeMenu}
          activeMenuElement={activeMenuElement}
          handleToggleActiveMenuElement={handleToggleActiveMenuElement}
          isAccountActive={isAccountActive}
          selectTone={selectTone}
        />
      </div>
      <div className="from-tablet:hidden">
        <HeaderMobile
          activeMenuElement={activeMenuElement}
          handleToggleActiveMenuElement={handleToggleActiveMenuElement}
          selectTone={selectTone}
          width={width}
        />
      </div>
    </BlockContent>
  );
}
