import * as React from "react";
import { useUserLoginState } from "@nju/data-access-portal-user";
import { useMachine } from "@xstate/react";
import { pushSimpleEvent } from "@nju/data-layer";
import { chatMachine } from "./chat.machine";

export function Chat() {
  const { isLoggedIn } = useUserLoginState();

  const [_, send] = useMachine(chatMachine, {
    context: {
      onClickOpenWidget: () => {
        pushSimpleEvent({
          event: "activity_chat",
          eventName: "chat_open",
        });
      },
    },
  });

  React.useEffect(() => {
    if (isLoggedIn) {
      send({ type: "ENABLE" });
    } else {
      send({ type: "DISABLE" });
    }
  }, [isLoggedIn, send]);

  // eslint-disable-next-line unicorn/no-null
  return null;
}
