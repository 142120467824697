import { VisuallyHidden } from "@react-aria/visually-hidden";
import Link from "next/link";
import { Icon } from "@nju/icons";

interface ILogoProps {
  href: string;
  tone?: "info" | "neutral";
}

export function Logo({ href, tone = "info" }: ILogoProps) {
  return (
    <Link data-cy="logo" href={href} title="Strona główna nju.pl">
      <Icon className="w-20 h-[32px]" name="nju" tone={tone} />
      <VisuallyHidden>Strona główna nju.pl</VisuallyHidden>
    </Link>
  );
}
