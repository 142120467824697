import * as React from "react";
import { useRouter } from "next/router";
import { maybeRefreshTokens } from "../maybeRefreshTokens";
import { isOk } from "@nju/result";

const Context = React.createContext<{
  isLoggedIn: boolean | undefined;
} | null>(
  // eslint-disable-next-line unicorn/no-null
  null
);

export function UserLoginStateProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | undefined>(
    false
  );

  const router = useRouter();

  React.useEffect(() => {
    async function recheck() {
      await maybeRefreshTokens({
        scope: "portal",
        canFail: true,
        justCheck: true,
      }).then((result) => {
        setIsLoggedIn(isOk(result));
      });
    }
    recheck();
    const id = setInterval(recheck, 5000);
    router.events.on("routeChangeComplete", recheck);
    return () => {
      router.events.off("routeChangeComplete", recheck);
      clearInterval(id);
    };
  }, [router.events]);

  const value = React.useMemo(() => {
    return { isLoggedIn };
  }, [isLoggedIn]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useUserLoginState() {
  const value = React.useContext(Context);

  if (value === null) {
    return {
      isLoggedIn: false,
      recheck: () => {
        return;
      },
    };
  }

  return value;
}
